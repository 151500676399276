import { action, makeObservable, observable } from "mobx";

class MainStore {
  @observable
  isVerificationPassed = false;
  @observable
  isLanguageDefined = false;
  @observable
  isAppLoaded = false;

  @action
  setAppLoaded() {
    if (this.isVerificationPassed && this.isLanguageDefined) {
      this.isAppLoaded = true;
    }
  }
  @action
  setVerificationPassed() {
    this.isVerificationPassed = true;
    this.setAppLoaded();
  }
  @action
  setLanguageDefined() {
    this.isLanguageDefined = true;
    this.setAppLoaded();
  }

  constructor() {
    makeObservable(this);
  }
}

const mainStore = new MainStore();

export default mainStore;
