import React, { FC, ReactChild } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const Wrapper: FC<{ children: ReactChild }> = ({ children }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={8}>
        <Paper sx={{ height: "80vh" }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={4} sx={wrapperStyles}>
              <img
                src="/images/vigram.svg"
                style={{ maxWidth: "50%" }}
                alt={"vigram®"}
              />
            </Grid>
            <Grid item xs={8} sx={formWrapperStyles}>
              <Box sx={formStyles}>{children}</Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const wrapperStyles = {
  background: "url('/images/bg.jpg') 70% 0% no-repeat",
  backgroundSize: "cover",
  borderRadius: "10px 0 0 10px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  height: "100%",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "inherit",
  width: "100%",
} as const;

const formWrapperStyles = {
  borderRadius: "0 10px 10px 0",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  height: "100%",
};

export default Wrapper;
