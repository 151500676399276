import React from "react";
import styled from "styled-components";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../../config";

const StyledLink = styled(Link)`
  margin: 0 5px;
`;

const Wrapper = styled.div`
  margin: 20px auto;
  max-width: 1100px;
  text-align: center;
`;

export default function Footer() {
  const date = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <Wrapper>
      © {date} vigram GmbH |
      <StyledLink
        underline="none"
        component={RouterLink}
        to={`/${config.react.publicURL}/imprint`}
      >
        {t("General:FooterImprint")}
      </StyledLink>
      |
      <StyledLink
        underline="none"
        component={RouterLink}
        to={`/${config.react.publicURL}/data-protection`}
      >
        {t("General:FooterDataProtection")}
      </StyledLink>
      |
      <StyledLink
        underline="none"
        component={RouterLink}
        to={`/${config.react.publicURL}/agbs`}
      >
        AGBs
      </StyledLink>
    </Wrapper>
  );
}
