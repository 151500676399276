import React, { useState, useEffect } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import MyRow from "./Row";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import PaginationActions from "./Actions";
import TableSortLabel from "@material-ui/core/TableSortLabel";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingDateComparator(a, b, dateField) {
  //let parts = a[dateField].split(".");
  //const dateA = new Date(`${parts[1]}.${parts[0]}.${parts[2]}`);
  //parts = b[dateField].split(".");
  //const dateB = new Date(`${parts[1]}.${parts[0]}.${parts[2]}`);
  const dateA = new Date(a);
  const dateB = new Date(b);
  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === "createdAt") {
    return order === "desc"
      ? (a, b) => descendingDateComparator(a, b, orderBy)
      : (a, b) => -descendingDateComparator(a, b, orderBy);
  }
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function MyTable({
  columnTitles,
  entries,
  fields,
  idFieldName,
  recursive,
  renderActions,
  onDelete,
  onRowClick,
  onEditClick,
  onAbortClick,
  creationDateField,
  expirationDateField,
  expiration,
  getWarningMessage,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(idFieldName);
  const [page, setPage] = useState(0);
  const rowsPerPage = 12;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer
      style={{ width: "100%", margin: "5px 0" }}
      component={Paper}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TablePagination
              count={entries.length}
              colSpan={recursive ? fields.length + 2 : fields.length + 1}
              rowsPerPageOptions={[]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
          <TableRow>
            {recursive && <TableCell />}
            {columnTitles.map((title, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active={orderBy === fields[index]}
                  direction={orderBy === fields[index] ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, fields[index])}
                >
                  {title}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {stableSort(entries, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((entry, i) => (
              <MyRow
                key={i}
                entry={entry}
                fields={fields}
                idFieldName={idFieldName}
                level={0}
                recursive={recursive}
                onRowClick={onRowClick}
                onEditClick={onEditClick}
                onAbortClick={onAbortClick}
                onDelete={onDelete}
                renderActions={renderActions}
                expiration={expiration}
                creationDateField={creationDateField}
                expirationDateField={expirationDateField}
                getWarningMessage={getWarningMessage}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MyTable;
