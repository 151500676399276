import { action, makeObservable, observable, runInAction } from "mobx";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import mainStore from "./mainStore";
import { v4 as uuidv4 } from "uuid";
import config from "../../config";

const userPool = new CognitoUserPool({
  UserPoolId: config.envs.cognitoId,
  ClientId: config.envs.clientId,
});

class AuthStore {
  @observable
  error = "";

  @observable
  values = {
    email: "",
    password: "",
    newPw: "",
    confirmNewPw: "",
    code: "",
  };

  @observable
  cognitoUser = {} as CognitoUser;
  @observable
  userAttributes = [] as CognitoUserAttribute[];

  @observable isFirstLogin = false;
  @observable isPasswordValid = true;
  @observable isPasswordMatch = true;
  @observable inProgress = false;

  @observable
  success = {
    resendCode: false,
    forgotPassword: false,
  };

  @action
  setEmail(email: string) {
    this.values.email = email;
  }
  @action
  setPassword(password: string) {
    this.values.password = password;
  }
  @action
  setNewPassword(newPw: string) {
    this.values.newPw = newPw;
  }
  @action
  setConfirmNewPassword(confirmNewPw: string) {
    this.values.confirmNewPw = confirmNewPw;
  }
  @action
  setCode(code: string) {
    this.values.code = code;
  }

  @action
  verifySession() {
    const cognitoUser = userPool.getCurrentUser();
    this.cognitoUser = cognitoUser!;

    return new Promise((res, rej) => {
      this.cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          return rej(err);
        }
        res(session);
      });
    })
      .then(
        () =>
          new Promise((res, rej) => {
            this.cognitoUser.getUserAttributes((err, attributes) => {
              if (err) {
                return rej(err);
              }
              res(attributes);
              runInAction(() => {
                this.userAttributes = attributes!;
              });
            });
          })
      )
      .catch(() => {})
      .finally(() => mainStore.setVerificationPassed());
  }

  @action
  refreshToken() {
    const username = this.cognitoUser.getUsername();
    const refreshToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.envs.clientId}.${username}.refreshToken`
    );
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken!,
    });

    return new Promise((res, rej) =>
      this.cognitoUser.refreshSession(cognitoRefreshToken, (err, result) => {
        if (err) {
          return rej(err);
        }
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${config.envs.clientId}.${username}.idToken`,
          result.idToken.jwtToken
        );
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${config.envs.clientId}.${username}.accessToken`,
          result.accessToken.jwtToken
        );
        res(result);
      })
    ).catch(() => {});
  }

  @action
  validatePassword(password: string) {
    this.isPasswordValid = true;
    const regexPassword =
      /^(?=^.{8,99}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*$/;
    if (!regexPassword.test(password)) {
      this.isPasswordValid = false;
    }
  }

  @action
  checkPasswordsMatch(password: string, confirmPassword: string) {
    if (password === confirmPassword) {
      this.isPasswordMatch = true;
    } else {
      this.isPasswordMatch = false;
    }
  }

  @action
  register() {
    localStorage.setItem("username", uuidv4());

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: this.values.email,
      }),
    ];

    this.inProgress = true;
    this.error = "";

    return new Promise((res, rej) =>
      userPool.signUp(
        localStorage.getItem("username")!,
        this.values.password,
        attributeList,
        [],
        (err, result) => {
          if (err) {
            return rej(err);
          }
          res(result);
        }
      )
    )
      .catch(
        action((err) => {
          if (err.code === "UserLambdaValidationException") {
            this.error = "This email address is already being used";
          } else {
            this.error = err.message;
          }
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  @action
  confirmRegistration() {
    this.inProgress = true;
    this.error = "";

    const cognitoUser = new CognitoUser({
      Username: localStorage.getItem("username")!,
      Pool: userPool,
    });

    return new Promise((res, rej) =>
      cognitoUser.confirmRegistration(this.values.code, true, (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
      })
    )
      .catch(
        action((err) => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  @action
  resendCode() {
    this.inProgress = true;
    this.error = "";
    this.success.resendCode = false;

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return new Promise((res, rej) =>
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
        this.success.resendCode = true;
      })
    )
      .catch(
        action((err) => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  @action
  login() {
    this.inProgress = true;
    this.error = "";

    const authenticationDetails = new AuthenticationDetails({
      Username: this.values.email,
      Password: this.values.password,
    });

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return new Promise((res, rej) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          window.location.reload();
          return res(result);
        },
        onFailure: (err) => {
          return rej(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          runInAction(() => {
            this.inProgress = !this.inProgress;
          });
          if (this.isFirstLogin) {
            cognitoUser.completeNewPasswordChallenge(
              this.values.newPw,
              this.userAttributes,
              {
                onSuccess: (result) => {
                  window.location.reload();
                  return res(result);
                },
                onFailure: (err) => {
                  return rej(err);
                },
              }
            );
          }
          runInAction(() => {
            this.isFirstLogin = true;
          });
        },
      });
    })
      .catch(
        action((err) => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  @action
  forgotPassword() {
    this.inProgress = true;
    this.error = "";

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return new Promise((res, rej) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          return res(result);
        },
        onFailure: (err) => {
          return rej(err);
        },
      });
    })
      .catch(
        action((err) => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  @action
  confirmPassword() {
    this.inProgress = true;
    this.error = "";

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return new Promise((res, rej) => {
      cognitoUser.confirmPassword(this.values.code, this.values.password, {
        onSuccess: (result) => {
          return res(result);
        },
        onFailure: (err) => {
          return rej(err);
        },
      });
    })
      .catch(
        action((err) => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  @action
  updateAttributes(attributesList: CognitoUserAttribute[]) {
    return new Promise((res, rej) => {
      this.cognitoUser.updateAttributes(attributesList, (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
        this.verifySession();
      });
    }).catch(
      action((err) => {
        throw err;
      })
    );
  }

  @action
  verifyEmail(verificationCode: string) {
    this.error = "";

    return new Promise((res, rej) => {
      this.cognitoUser.verifyAttribute("email", verificationCode, {
        onSuccess: (result) => {
          this.verifySession();
          return res(result);
        },
        onFailure: (err) => {
          return rej(err);
        },
      });
    }).catch(
      action((err) => {
        this.error = err.message;
        throw err;
      })
    );
  }

  @action
  getEmailVerificationCode() {
    this.error = "";

    return new Promise((res, rej) => {
      this.cognitoUser.getAttributeVerificationCode("email", {
        onSuccess: (result) => {
          return res(result);
        },
        onFailure: (err) => {
          return rej(err);
        },
      });
    }).catch(
      action((err) => {
        this.error = err.message;
        throw err;
      })
    );
  }

  @action
  changePassword(currentPassword: string, newPassword: string) {
    this.error = "";

    return new Promise((res, rej) => {
      this.cognitoUser.changePassword(
        currentPassword,
        newPassword,
        (err, result) => {
          if (err) {
            return rej(err);
          }
          res(result);
          console.log(result);
        }
      );
    }).catch(
      action((err) => {
        this.error = err.message;
        throw err;
      })
    );
  }

  @action
  logout() {
    return new Promise(() => {
      if (this.cognitoUser !== null) {
        this.cognitoUser.signOut();
        window.location.reload();
      }
    });
  }

  constructor() {
    makeObservable(this);
  }
}

const authStore = new AuthStore();

export default authStore;
