import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Wrapper from "../Wrapper";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import authStore from "../authStore";

const ForgotPassword: FC = () => {
  const { t } = useTranslation();

  const { error, success, cognitoUser } = authStore;

  const history = useHistory();

  useEffect(() => {
    if (cognitoUser) {
      history.push("/");
    }
  }, []);

  return (
    <Wrapper>
      <>
        <Typography component="h1" variant="h5">
          {t("Auth:ResetYourPassword")}
        </Typography>
        <Typography sx={styles} variant="subtitle1">
          {success.forgotPassword
            ? `${t("Auth:SuccessForgotPasswordMessage")}`
            : `${t("Auth:EnterEmailMessage")}`}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1" color="error">
          {error}
        </Typography>
        {success.forgotPassword ? <Stage2 /> : <Stage1 />}
        <Grid container>
          <Grid item>
            <Link href="/login" variant="body2" underline="hover">
              {t("Auth:GoBackToLogin")}
            </Link>
          </Grid>
        </Grid>
      </>
    </Wrapper>
  );
};

const styles = { mt: 2, color: "primary.light" };

export default observer(ForgotPassword);
