import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import config from "./config";
import authStore from "./components/Auth/authStore";
require("dotenv").config();

if (window.location.hostname === "vipoint.vigram.com") {
  console.log = function () {};
}

axios.interceptors.request.use(
  async (axiosConfig) => {
    const user = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.envs.clientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
      `CognitoIdentityServiceProvider.${config.envs.clientId}.${user}.idToken`
    );
    axiosConfig.headers = {
      Authorization: `Bearer ${token}`,
    };
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await authStore.refreshToken();
      originalRequest.headers.Authorization = "Bearer " + access_token;
      return axios(originalRequest);
    }
    return error;
  }
);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
