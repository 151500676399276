const utils = {
  humanFileSize: (size) => {
    let i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  },

  NumToTime: (num) => {
    num = Math.floor(num);
    const hours = Math.floor(num / 3600);
    num = num % 3600;
    const minutes = Math.floor(num / 60);
    num = num % 60;
    const seconds = Math.floor(num);
    const ans = [];
    if (hours !== 0) {
      ans.push(hours + " Std");
    }
    if (minutes !== 0) {
      ans.push(minutes + " Min.");
    }
    if (seconds !== 0) {
      ans.push(seconds + " Sek.");
    }
    if (ans.length === 0) {
      ans.push("0 Sek");
    }
    return ans.join(" ");
  },

  formatDate: (date, customOptions) => {
    const options = customOptions
      ? customOptions
      : { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("de-DE", options);
  },

  fromDDMMToMMDD: (date) => {
    let parts = date.split(".");
    const reformattedDate = new Date(`${parts[1]}.${parts[0]}.${parts[2]}`);
    return reformattedDate;
  },

  formatPrice: (amount) => {
    return Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(amount / 100);
  },
};

export default utils;
