import React, { useState, useEffect } from "react";
import controller from "../controllers/fileUploaderController";

function FileUploader({ uploadObject, onUploadProgress }) {
  useEffect(() => {
    async function onFileReception(uploadObject, onUploadProgress) {
      try {
        await controller.multipartUploadS3(
          uploadObject.projectId,
          uploadObject.file,
          uploadObject.fileName,
          onUploadProgress
        );
        await controller.registerSuccessfulUpload(
          uploadObject.projectId,
          uploadObject.file,
          uploadObject.fileName,
          uploadObject.fileDescription
        );
        onUploadProgress(true, uploadObject.projectId, uploadObject.fileName);
      } catch (e) {
        console.log(e);
      }
    }

    if (uploadObject) {
      onFileReception(uploadObject, onUploadProgress);
    }
  }, [uploadObject]);

  return <React.Fragment></React.Fragment>;
}

export default FileUploader;
