import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import controller from '../controllers/projectsController';
import Typography from "@material-ui/core/Typography";

function MyEditModal({object, fields, editable, url, open, closeModal, idFieldName, editObject, titles }) {
  const [editedObject, setEditedObject] = useState(object);

  const { t } = useTranslation();

  const handleChange = event => {
    const inputId = event.target.id;
    const inputVal = event.target.value;
    const editedObjectBuffer = {...editedObject};
    editedObjectBuffer[inputId] = inputVal;
    setEditedObject(editedObjectBuffer);
  }


  return (
    <Modal
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MyPaper>
          {fields.map((field, index) => (
          <MyTextField
            id={field}
            label={titles[index]}
            variant="filled"
            value={editedObject[field]}
            disabled={!editable.includes(field)}
            onChange={handleChange}
            size="small"/>
          ))}

          <Button
            style={{alignSelf: 'flex-end'}}
            size='small' 
            variant="contained" 
            color="secondary"
            onClick={() => editObject(editedObject)}>
            {t("General:Edit")}
          </Button>
        </MyPaper>
      </Fade>
    </Modal>
  );
}

const MyTextField = styled(TextField)`
  margin-bottom: 20px;
`;

const MyPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width 400px;
  outline: none;
  padding: 25px 15px;
`;

export default MyEditModal;
