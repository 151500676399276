import axios from "axios";
import config from "../config";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const CHUNK_SIZE = 1500_000_000;

const controller = {
  registerSuccessfulUpload: async function (
    projectId,
    file,
    fileName,
    fileDescription
  ) {
    await axios.post(`${prefix}/files/${projectId}`, {
      fileName,
      size: file.size,
      fileDescription,
    });
  },

  multipartUploadS3: async function (
    projectId,
    file,
    fileName,
    onUploadProgress
  ) {
    const partsAmount = Math.ceil(file.size / CHUNK_SIZE);
    const uploadInfo = await initiateMultipartUploadS3(
      fileName,
      partsAmount,
      projectId
    );
    const parts = await uploadParts(
      file,
      fileName,
      uploadInfo.data.partsMap,
      onUploadProgress,
      projectId
    );
    console.log(parts);
    const data = encodeURIComponent(btoa(JSON.stringify(parts)));
    const response = await axios.get(
      `${prefix}/files/${projectId}/complete-multipart-upload?uploadId=${uploadInfo.data.uploadId}&objectName=${fileName}&data=${data}`
    );
    return response;
  },
};

const initiateMultipartUploadS3 = async (objectName, parts, projectId) => {
  const response = await axios.get(
    `${prefix}/files/${projectId}/initiate-multipart-upload?objectName=${objectName}&parts=${parts}`
  );
  console.log(response.data);
  return response.data;
};

const uploadParts = async (
  file,
  fileName,
  urls,
  onUploadProgress,
  projectId
) => {
  const uploadedSizes = [];
  let uploadedSize = 0;

  uploadedSizes.push(0);

  const keys = Object.keys(urls);
  const promises = [];

  for (const indexStr of keys) {
    const index = parseInt(indexStr) - 1;
    const start = index * CHUNK_SIZE;
    const end = (index + 1) * CHUNK_SIZE;

    console.log("Sending part ", indexStr, " to ", urls[index + 1]);

    const blob =
      (index + 1) * CHUNK_SIZE <= file.size
        ? file.slice(start, end)
        : file.slice(start);

    uploadedSizes.push(0);

    const config = {
      onUploadProgress: (progressEvent) => {
        uploadedSize += progressEvent.loaded - uploadedSizes[index + 1];
        uploadedSizes[index + 1] = progressEvent.loaded;
        onUploadProgress(false, projectId, fileName, uploadedSize / file.size);
      },
    };

    const axiosInstance = axios.create();
    promises.push(axiosInstance.put(urls[index + 1], blob, config));

    if ((index + 1) * CHUNK_SIZE >= file.size) {
      break;
    }
  }

  const resParts = await Promise.all(promises);

  return resParts.map((part, index) => {
    return {
      ETag: part.headers.etag,
      PartNumber: index + 1,
    };
  });
};

export default controller;
