import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import authStore from "../authStore";

const Stage1: FC = () => {
  const { t } = useTranslation();

  const { values, inProgress, success } = authStore;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setEmail(e.target.value);
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.forgotPassword().then(() => {
      success.forgotPassword = true;
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmitForm} sx={styles}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label={t("Auth:Email")}
            type="email"
            name="email"
            autoComplete="email"
            value={values.email}
            onChange={handleEmailChange}
            color="secondary"
            sx={textFieldsStyles}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        color="secondary"
        variant="contained"
        sx={buttonStyles}
        disabled={inProgress}
        disableElevation
      >
        {t("Auth:ResetPassword")}
      </Button>
    </Box>
  );
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608", 
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608", 
      borderColor: "#ec6608", 
      "& legend": {
        color: "#ec6608", 
      },
    },
  },
};

const buttonStyles = {
  mt: 3, 
  mb: 2, 
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const styles = { mt: 3, width: "100%" };

export default observer(Stage1);
