import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import authStore from "../authStore";

const Stage2: FC = () => {
  const { t } = useTranslation();

  const passwordValidationError = t("Auth:PasswordValidationError");

  const { values, inProgress, isPasswordValid } = authStore;

  const history = useHistory();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setPassword(e.target.value);
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setCode(e.target.value);
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.validatePassword(values.password);
    authStore.isPasswordValid &&
      authStore.confirmPassword().then(() => history.replace("/login"));
  };

  return (
    <Box component="form" onSubmit={handleSubmitForm} sx={styles}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="code"
            label={t("Auth:CodeLabel")}
            type="text"
            id="code"
            value={values.code}
            onChange={handleCodeChange}
            color="secondary"
            sx={textFieldsStyles}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!isPasswordValid}
            required
            fullWidth
            name="password"
            label={t("Auth:NewPasswordLabel")}
            type="password"
            id="password"
            autoComplete="new-password"
            value={values.password}
            onChange={handlePasswordChange}
            helperText={!isPasswordValid && passwordValidationError}
            color="secondary"
            sx={textFieldsStyles}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        color="secondary"
        variant="contained"
        sx={buttonStyles}
        disabled={inProgress}
        disableElevation
      >
        {t("Auth:Confirm")}
      </Button>
    </Box>
  );
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608", 
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608", 
      borderColor: "#ec6608", 
      "& legend": {
        color: "#ec6608", 
      },
    },
  },
};

const buttonStyles = {
  mt: 3, 
  mb: 2, 
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const styles = { mt: 3, width: "100%" };

export default observer(Stage2);
