import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Imprint() {
  return (
    <div>
      <Typography
        component="h4"
        variant="h4"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Angaben gemäß § 5 TMG
      </Typography>
      <Typography paragraph variant="body1">
        vigram GmbH
        <br />
        Jechtinger Straße 21
        <br />
        79111 Freiburg
      </Typography>
      <Typography paragraph variant="body1">
        Handelsregister: 720668
        <br />
        Registergericht: Amtsgericht Freiburg im Breisgau
      </Typography>
      <Typography component="h6" variant="h6" style={{ fontWeight: 500 }}>
        Vertreten durch:
      </Typography>
      <Typography paragraph variant="body1">
        Sidon Futterknecht
      </Typography>
      <br />
      <Typography paragraph variant="body1">
        vigram AG
        <br />
        Zugerstrasse 116
        <br />
        CH 6333 Cham
      </Typography>
      <Typography paragraph variant="body1">
        Handelsregister Zug: CH-020.3.004.902-4
        <br />
        UID: CHE-101.648.894
      </Typography>
      <Typography component="h6" variant="h6" style={{ fontWeight: 500 }}>
        Vertreten durch:
      </Typography>
      <Typography paragraph variant="body1">
        Sidon Futterknecht
      </Typography>
      <Typography
        component="h4"
        variant="h4"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Kontakt
      </Typography>
      <Typography paragraph variant="body1">
        Telefon: +49 163 726 7707
        <br />
        E-Mail: info(at)vigram.com
      </Typography>
      <Typography
        component="h4"
        variant="h4"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Umsatzsteuer-ID
      </Typography>
      <Typography paragraph variant="body1">
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
        <br />
        DE326904596
      </Typography>
      <Typography
        component="h4"
        variant="h4"
        paragraph
        style={{ fontWeight: 500 }}
      >
        EU-Streitschlichtung
      </Typography>
      <Typography paragraph variant="body1">
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <Link
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://ec.europa.eu/consumers/odr/
        </Link>
        .
        <br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </Typography>
      <Typography
        component="h4"
        variant="h4"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
      </Typography>
      <Typography paragraph variant="body1">
        Wir nehmen an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil. Zuständig ist die
        Universalschlichtungsstelle des Zentrums für Schlichtung e.V.,
        Straßburger Straße 8, 77694 Kehl am Rhein (
        <Link
          href="https://www.verbraucher-schlichter.de"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://www.verbraucher-schlichter.de
        </Link>
        ).
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Haftung für Inhalte
      </Typography>
      <Typography paragraph variant="body1">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen.
      </Typography>
      <Typography paragraph variant="body1">
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Haftung für Links
      </Typography>
      <Typography paragraph variant="body1">
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      </Typography>
      <Typography paragraph variant="body1">
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Urheberrecht
      </Typography>
      <Typography paragraph variant="body1">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </Typography>
      <Typography paragraph variant="body1">
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </Typography>
    </div>
  );
}
