import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress';
import controller from '../../controllers/projectsController';
import AddIcon from '@material-ui/icons/Add';
import MyCreateProjectModal from './MyCreateProjectModal';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';
import MyTable from '../Table/Table';
import renderActions from "./Actions";
import MyEditModal from "../MyEditModal";
import IconButton from '@material-ui/core/IconButton';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (projectToEdit != null) {
      setEditModalOpen(true);
    }
  }, [projectToEdit]);

  const init = () => {
    getAndSetProjects();
  }

  const getAndSetProjects =  async () => {
      const aProjects = await controller.getProjects();
      setProjects(aProjects);
      setLoaded(true);
  }

  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const onEditClick = (project) => {
    setProjectToEdit({...project});
  }

  const closeEditModal = () => {
    setEditModalOpen(false);
  }

  const createProject = (newProject) => {
    const projectsBuffer = [...projects];
    projectsBuffer.unshift(newProject);
    setProjects(projectsBuffer);
    setModalOpen(false);
  }

  const onDelete = () => {
    getAndSetProjects();
  }

  const openProject = (projectId) => {
    history.push(`projects/${projectId}`);
  }

  const editProject = async (editedProject) => {
    console.log(`Object has just changed to`, editedProject);
    try {
      await controller.editProject(editedProject.projectId, editedProject);
      closeEditModal();
      getAndSetProjects();
    } catch (e) {
      window.alert(t("Projects:MessageWrong"));
    }
  }

  return (
    <React.Fragment>
      {!loaded && <CircularProgress />}
      {loaded &&
      <React.Fragment>
        <div style={{width: "100%", display: "flex"}}>
          <Tooltip title={t("Projects:Create")}>
            <IconButton
              style={{padding: "0"}}
              color="secondary" 
              onClick={openModal}>
              <AddIcon fontSize="large"/>
            </IconButton>
          </Tooltip>
        </div>

        {projects.length > 0 ?
        <MyTable 
          entries={projects}
          fields={['projectName', 'projectDescription', 'createdAt']}
          columnTitles={[t("Projects:ProjectName"), t("Projects:Description"), t("Projects:CreatedAt")]}
          idFieldName={'projectId'}
          renderActions={renderActions}
          onDelete={onDelete}
          onRowClick={openProject}
          onEditClick={onEditClick}
          creationDateField={"createdAt"}
        /> :
        <h4>{t("Projects:NoProjects")}</h4>}

        <MyCreateProjectModal 
          closeModal={closeModal}
          open={modalOpen}
          onCreate={createProject}
        />

        {editModalOpen &&
        <MyEditModal
          closeModal={closeEditModal}
          open={editModalOpen}
          object={projectToEdit}
          idFieldName={"projectId"}
          fields={["projectName", "projectDescription", "createdAt"]}
          titles={[t("Projects:Name"), t("Projects:Description"), t("Projects:CreatedAt")]}
          editable={["projectName", "projectDescription"]}
          editObject={editProject}
        />}

      </React.Fragment>
      }
    </React.Fragment>
  );
}

export default Projects;
