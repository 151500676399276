import React from "react"
import BlurOnIcon from "@material-ui/icons/BlurOn"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import GetAppIcon from "@material-ui/icons/GetApp"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import controller from "../../controllers/filesController.js"
import config from "../../config"

function Actions({ onDelete, entry, onEditClick }) {
  const history = useHistory()
  const { projectId } = useParams()

  const { t } = useTranslation()

  const proto = "https:"
  const api = config.api
  const prefix = `${proto}//${api.host}/${api.base}`

  const onProcessClick = () => {
    history.push(
      `/${config.react.publicURL}/projects/${projectId}/${entry.fileId}/processing`
    )
  }

  const onDownloadS3Click = async () => {
    // TODO: improve error handling, it's unsafe way to extract link value
    const { data: link } = await controller.getS3DownloadLink(projectId, entry.fileId)
    window.open(link)
  }

  const onDeleteClick = async () => {
    if (window.confirm(t("Files:onDelete"))) {
      try {
        await controller.deleteFile(projectId, entry.fileId)
        onDelete(entry.fileId)
      } catch (e) {
        console.log(e)
        window.alert(t("Files:SomethingWrong"))
      }
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Tooltip title={t("Files:Proccess")}>
        {entry.name.slice(-4) !== ".dxf" ? (
          <IconButton
            style={{ padding: "0" }}
            color="secondary"
            onClick={onProcessClick}
          >
            <BlurOnIcon fontSize="medium" />
          </IconButton>
        ) : (
          <span>
            <IconButton style={{ padding: "0" }} color="secondary" disabled>
              <BlurOnIcon fontSize="medium" />
            </IconButton>
          </span>
        )}
      </Tooltip>

      <Tooltip title={t("Files:Download")}>
        <IconButton
          style={{ padding: "0" }}
          color="secondary"
          onClick={onDownloadS3Click}
        >
          <GetAppIcon fontSize="medium" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("Files:Edit")}>
        <IconButton
          style={{ padding: "0" }}
          color="secondary"
          onClick={() => onEditClick(entry)}
        >
          <EditIcon fontSize="medium" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("Files:Delete")}>
        <IconButton
          style={{ padding: "0" }}
          color="secondary"
          onClick={onDeleteClick}
        >
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

const renderActions = (onDelete, entry, onEditClick) => {
  return (
    <Actions onDelete={onDelete} entry={entry} onEditClick={onEditClick} />
  )
}

export default renderActions
