import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyTable from "../Table/Table";
import controller from "../../controllers/historyController";
import CircularProgress from "@material-ui/core/CircularProgress";
import renderActions from "./Actions";

function History() {
  const { projectId } = useParams();
  const [processings, setProcessings] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAndSetHistory(projectId);
  }, [i18n.language]);

  useEffect(() => {
    const interval = setInterval(() => {
      getAndSetHistory(projectId);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const getAndSetHistory = async (projectId) => {
    const fetchedHistory = await controller.getHistory(projectId);
    fetchedHistory.forEach((el) => {
      el.status = t(`Proccessing:${el.status}`);
    });
    setProcessings(fetchedHistory);
    setLoaded(true);
  };

  const download = async (fileId, fileName) => {
    controller.downloadFile(projectId, fileId, fileName);
  };

  const openProcessing = (processingId) => {
    history.push(`history/${processingId}`);
  };

  const onAbortClick = async (entry) => {
    try {
      if (window.confirm(t("General:Shure"))) {
        await controller.abortProcessing(projectId, entry.processingId);
        getAndSetHistory(projectId);
      }
    } catch (e) {
      window.alert(t("General:SomethingWrong"));
    }
  };

  return (
    <React.Fragment>
      {!loaded && <CircularProgress />}
      {loaded && (
        <React.Fragment>
          {processings.length > 0 ? (
            <MyTable
              entries={processings}
              fields={["processingName", "alias", "status", "createdAt"]}
              columnTitles={[
                t("General:HistoryName"),
                t("General:HistoryType"),
                t("General:HistoryStatus"),
                t("General:HistoryCreatedAt"),
              ]}
              idFieldName={"processingId"}
              renderActions={renderActions}
              onDelete={null}
              onRowClick={openProcessing}
              onAbortClick={onAbortClick}
              creationDateField={"createdAt"}
            />
          ) : (
            <h4>{t("General:NoProccessingFiles")}</h4>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default History;
