import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

function MyNavListItem(props) {
  const history = useHistory();

  return (
    !props.invisible && (
      <ListItem button onClick={() => history.push(props.urlLink)}>
        <MyListItemIcon>{props.icon}</MyListItemIcon>

        <MyListItemText open={props.open} primary={props.text} />
      </ListItem>
    )
  );
}

//Styling
const MyListItemText = styled(ListItemText)`
  opacity: ${(props) => (props.open ? "1" : "0")};
  transition: ${(props) =>
    props.open ? "all 0.3s ease-in" : "all 0.1s linear"};
  margin: 2px 0;
`;

const MyListItemIcon = styled(ListItemIcon)`
  padding-left: 8px;
  min-width: 40px;
`;
//Styling

export default MyNavListItem;
