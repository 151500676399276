import React, { FC } from "react";
import "./translation/translationService";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

const languages = [
  {
    id: "en",
    title: "English",
    flag: <img alt="English" src="/images/languages/English.svg" />,
  },
  {
    id: "de",
    title: "Deutsch",
    flag: <img alt="German" src="/images/languages/German.svg" />,
  },
];

const LanguageSelector: FC = ({ active }: any) => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    localStorage.setItem("i18nextLng", event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <React.Fragment>
      {active && 
        <FormControl>
          <Select
            id="language-selector"
            defaultValue={localStorage.getItem("i18nextLng") || "de"}
            value={localStorage.getItem("i18nextLng") || "de"}
            variant="standard"
            sx={selectorStyles}
            onChange={handleChange}
            size="small"
            autoWidth
            IconComponent={KeyboardArrowDown}
          >
            {languages.map((lang) => (
              <MenuItem value={lang.id} key={lang.id}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {lang.flag}
                  <Typography sx={{ ml: 1 }} color="primary" variant="subtitle1">
                    {lang.title}
                  </Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </React.Fragment>
  );
};

const selectorStyles = {
  my: 2,
  ":before": {
    border: "none",
    content: "none",
  },
  ":after": {
    border: "none",
  },
  "& .MuiSelect-select:focus": { backgroundColor: "transparent" },
  ":hover:not(.Mui-disabled):before": {
    border: "none",
  },
  "& .MuiTypography-root": {color: "white"},
  "& .MuiSvgIcon-root": {color: "white"},
};

export default LanguageSelector;
