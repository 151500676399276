import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import controller from "../../controllers/contingentController";

function MyUploadFileModal({ onUploadFile, open, closeModal }) {
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [accountCreditTotal, setAccountCreditTotal] = useState();
  const [fullStorage, setFullStorage] = useState(false);
  const [largeFile, setLargeFile] = useState(false);

  const { t } = useTranslation();

  const handleChange = (event) => {
    const inputVal = event.target.value;
    setDescription(inputVal);
  };

  useEffect(() => {
    setFiles([]);
    setDescription("");
  }, [open]);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    getContingentSum();
  };

  const getContingentSum = async () => {
    const ContingentSum = await controller.getContingentSum();
    setAccountCreditTotal(ContingentSum[0].accountCreditTotal * 1000000000);
  };

  const onFileChange = (event) => {
    setFullStorage(false);
    setLargeFile(false);
    setFiles([...event.target.files]);
    const FileSize = Array.from([...event.target.files]).map(function (file) {
      return file.size;
    });
    const checkFileLimit = FileSize.every((file) => file <= 30000000000);
    const FilesSum = FileSize.reduce(function (previous, current) {
      return previous + current;
    }, 0);
    checkLimits(FilesSum, checkFileLimit);
  };

  const checkLimits = (FilesSum, checkFileLimit) => {
    if (FilesSum >= accountCreditTotal) {
      setFullStorage(true);
    }
    if (checkFileLimit == false) {
      setLargeFile(true);
    }
  };

  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <MyPaper>
          <H4>{t("Files:Upload")}:</H4>

          <MyTextField
            id="description"
            label={t("Files:Description")}
            multiline
            minRows={3}
            onChange={handleChange}
            variant="filled"
            size="small"
          />

          {files.map((file, index) => (
            <Typography
              key={index}
              variant="body2"
              style={{ wordBreak: "break-all" }}
            >
              {file.name}
            </Typography>
          ))}

          {fullStorage && <ErrorDiv>{t("Files:UploadQuotaError")}</ErrorDiv>}

          {largeFile && <ErrorDiv>{t("Files:FileSizeError")}</ErrorDiv>}

          <Button
            style={{
              marginTop: "10px",
              borderStyle: "dashed",
              alignSelf: "start",
            }}
            variant="outlined"
            component="label"
            size="small"
          >
            {t("Files:ChooseFiles")}
            <input
              hidden
              type="file"
              accept="image/vnd.dxf, .dxf, application/vnd.las, .las, application/vnd.laszip, .laz, text/csv"
              multiple
              onChange={onFileChange}
            />
          </Button>
          <Typography variant="caption" style={{ marginTop: "5px" }}>
            {t("Files:SelectUploadedFiles")}
          </Typography>

          <Button
            style={{ width: "80px", alignSelf: "flex-end" }}
            size="small"
            variant="outlined"
            color="secondary"
            disabled={fullStorage || largeFile}
            onClick={() => onUploadFile(files, description)}
          >
            {t("Files:Upload")}
          </Button>
        </MyPaper>
      </Fade>
    </Modal>
  );
}

const MyTextField = styled(TextField)`
  margin-bottom: 20px;
`;

const H4 = styled.h4`
  margin: 0px 0px 10px;
`;

const MyPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width 400px;
  outline: none;
  padding: 10px 10px;
`;

const ErrorDiv = styled.div`
  color: red;
  font-size: 12px;
`;

export default MyUploadFileModal;
