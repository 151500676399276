import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function BooleanInput({parameter, onValueUpdate}) {
  const [value, setValue] = useState(false);

  const { t } = useTranslation();

  const handleChange = (event) => {
    setValue(event.target.checked);
    onValueUpdate({
      payload: {
        parameterId: event.target.name,
        value: event.target.checked,
        selected: true
      }
    });
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={handleChange}
          name={parameter.parameterId}
          color="primary"
          size='small'
        />
      }
      label={t(`ProccessingBackend:Parameters:${parameter.parameterId}:parameterName`)}
    />
  );
}

//error={isValid[parameter.parameterId]}
export default BooleanInput;
