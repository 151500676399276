import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useLocation } from "react-router-dom";

const PaymentMethodResult = () => {
  const stripe = useStripe();
  const location = useLocation();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    (async () => {
      if (!stripe) return;
      const clientSecret = new URLSearchParams(location.search).get('setup_intent_client_secret');
      const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
      console.log(setupIntent);
      switch (setupIntent.status) {
        case 'succeeded':
          setMessage('Success! Your payment method has been saved.');
          break;

        case 'processing':
          setMessage("Processing payment details. We'll update you when processing is complete.");
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Failed to process payment details. Please try another payment method.');
          break;
        default:
          setMessage(setupIntent.status);
      }
    })();
  }, [stripe])


  return <div> {message} </div>
}

export default PaymentMethodResult;