import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components";
import config from "../../config";
import Login from './Login'
import LanguageSelector from "../LanguageSelector";


const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

export default function MyAppBar(props) {
 
  return (
    <AppBar position="fixed" color="inherit">
      <StyledToolbar>
        <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
          <IconButton
            edge="start"
            aria-label="menu"
            style={{ color: "#fff" }}
            onClick={props.loggedIn && props.toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <div style={{margin: "0 0 0 10px"}}>
            <LanguageSelector active ={true}/>
          </div>
        </div> 

        <div
          style={{
            background: `url(/${config.react.publicURL}/ViPoint.png) center center no-repeat`,
            backgroundSize: "contain",
            height: "64px",
            width: "120px",
          }}
        />
        
        <Login
          loggedIn={props.loggedIn}
        /> 
      </StyledToolbar>
    </AppBar>
  );
}
