const parts = window.location.pathname.split("/");
let subDomain = null;
let baseDomain =
  parts[1] === "service" || parts[1] === "rib" ? parts[1] : "service";
if (parts.length > 2) {
  const possibleSubDomain = parts[2];
  if (possibleSubDomain.length !== 0 && possibleSubDomain === "rib") {
    subDomain = possibleSubDomain;
  } else {
    console.log("No Subdomain found");
  }
}

const domain =
  subDomain !== null ? `${baseDomain}/${subDomain}` : `${baseDomain}`;

const config = {
  api: {
    // host: '71ugfqb5tg.execute-api.eu-central-1.amazonaws.com', // `m2c0qosil3.execute-api.eu-central-1.amazonaws.com`,
    host: `zqhq8ti8nf.execute-api.eu-central-1.amazonaws.com`,
    base: `api`,
  },
  react: {
    publicURL: `${domain}`,
  },
  envs: {
    // stripe: `pk_test_51JAa3cI4PhRzgdI1qxkxfaHWwnnhjQUzyq4U8gCzU0n2MU6Jq0AOcwGBpEzc4UrhkaAQjF4XziwiFp92rTaQRUUr00D6zUcv3B`,//`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`,
    stripe: 'pk_test_51Is2SLB5pzxsRDr4gbp0pLCKYvwWk0aKlZCbfFsUoklQ5IGnq1CbKS83rIxWU81zdSXZGaOIjukcX7B3ADftUVej00wl5QDwc6',
    cognitoId: `eu-central-1_7PBGxs6Qb`,//`${process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID}`,
    clientId: `77gp5fjth1n8irbche3aj4tpd7`//`${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}`
  },
};

export default config; 