import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import controller from "../../controllers/processingInitiationController";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import MySelector from "./MySelector";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NewParametersSetter from "./NewParametersSetter";
import config from "../../config.js";

function ProcessingInitiation() {
  const [categories, setCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [scripts, setScripts] = useState();
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [selectedScriptId, setSelectedScriptId] = useState();
  const [parameters, setParameters] = useState([]);
  const [parametersLoaded, setParametersLoaded] = useState(false);
  const [parametersValues, setParametersValues] = useState([]);
  const [dxfFiles, setDxfFiles] = useState([]);
  const [csvFiles, setCsvFiles] = useState([]);
  const [pointCloudFiles, setPointCloudFiles] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const { projectId, fileId } = useParams();
  const history = useHistory();

  const { t } = useTranslation();
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setParameters([]);
    setScripts([]);
    setSelectedScriptId(null);
    getAndSetScripts(selectedCategoryId);
    setParametersLoaded(false);
  }, [selectedCategoryId]);

  useEffect(() => {
    setParametersLoaded(false);
    setParameters([]);
    getAndSetParameters(projectId, selectedScriptId);
    setParametersValues([]);
  }, [selectedScriptId]);

  useEffect(() => {
    console.log("Parameters values have changed to: ", parametersValues);
  }, [parametersValues]);

  const init = () => {
    getAndSetCategories();
    getAndSetScripts();
  };

  const getAndSetCategories = async () => {
    const fetchedCategories = await controller.getCategories();
    setCategories(fetchedCategories);
    setCategoriesLoaded(true);
  };

  const getAndSetScripts = async (selectedCategoryId) => {
    const fetchedScripts = await controller.getScripts(selectedCategoryId);
    setScripts(fetchedScripts);
    setScriptsLoaded(true);
  };

  const getAndSetParameters = async (projectId, selectedScriptId) => {
    const fetchedParameters = await controller.getParameters(selectedScriptId);
    console.log(fetchedParameters);
    const fetchedFiles = await controller.getDxfFiles(projectId);
    const fetchedCsvFiles = await controller.getCsvFiles(projectId);
    const fetchedPointCloudFiles = await controller.getPointCloudFiles(projectId, fileId);
    setDxfFiles(fetchedFiles);
    setCsvFiles(fetchedCsvFiles);
    setPointCloudFiles(fetchedPointCloudFiles)
    setParameters(fetchedParameters);
    setParametersLoaded(true);
  };

  const onCategorySelect = (event) => {
    setSelectedCategoryId(event.target.value);
  };

  const onScriptSelect = (event) => {
    setSelectedScriptId(event.target.value);
  };

  const getCategoryEntries = () => {
    const entries = categories.map((category) => {
      return {
        id: category.categoryId,
        name: t(`ProccessingBackend:Categories:${category.categoryId}`),
      };
    });
    return entries;
  };

  const getScriptEntries = () => {
    const entries = scripts.map((script) => {
      return {
        id: script.scriptId,
        name: t(`ProccessingBackend:Scripts:${script.scriptId}:alias`),
      };
    });
    return entries;
  };

  const onParametersValuesUpdate = (arr) => {
    setParametersValues(arr);
  };

  const handleChange = (event) => {
    switch (event.target.id) {
      case "name":
        setName(event.target.value);
        break;
      case "description":
        setDescription(event.target.value);
        break;
    }
  };

  const postRequest = async (event) => {
    if (containErrors(parametersValues)) {
      window.alert(t("Proccessing:InvalidField"));
      setInProgress(false);
      return;
    }
    setInProgress(true);
    let data = {};
    data.name = name;
    data.description = description;
    data.fileId = fileId;
    data.scriptId = selectedScriptId;
    data.projectId = projectId;
    data.parameters = parametersValues.map((val) => {
      const filteredParameter = parameters.filter(
        (param) => param.parameterId === val.parameterId
      )[0];
      val.aliasBackend = filteredParameter.aliasBackend;
      val.parameterType = filteredParameter.parameterType;
      return val;
    });

    console.log("Post data is: ", data);
    if (!dataIsComplete(data)) {
      window.alert(t("Proccessing:DataIsNotComplete"));
      setInProgress(false);
      return;
    }
    const response = await controller.initiateProcessing(data);

    if (response.status != 201) {
      window.alert(t("Proccessing:SomethingWrong"));
      setInProgress(false);
    } else {
      history.push(`/${config.react.publicURL}/projects/${projectId}/history`);
    }
  };

  const containErrors = (parameterValues) => {
    for (let obj of parameterValues) {
      if (obj.error) {
        return true;
      }
    }
    return false;
  };

  const dataIsComplete = () => {
    if (name == "") {
      return false;
    }
    if (description == "") {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      <StyledSelectorsContainer>
        <div>
          {categoriesLoaded && (
            <MySelector
              size="small"
              label={t("Proccessing:Categories")}
              setSelectedItem={onCategorySelect}
              entries={getCategoryEntries()}
            />
          )}

          {scriptsLoaded && scripts.length > 0 && (
            <MySelector
              size="small"
              label={t("Proccessing:Functions")}
              setSelectedItem={onScriptSelect}
              entries={getScriptEntries()}
            />
          )}
        </div>
        <StyledButton
          variant="outlined"
          color="secondary"
          onClick={postRequest}
          disabled={inProgress}
        >
          start
        </StyledButton>
      </StyledSelectorsContainer>

      {selectedScriptId &&
        parametersLoaded &&
        scripts[scripts.findIndex((el) => el["scriptId"] == selectedScriptId)]
          .description && (
          <div
            style={{
              width: "90%",
              whiteSpace: "pre-wrap",
              margin: "0px 0px 15px",
              padding: "0px 8px",
              boxSizing: "border-box",
            }}
          >
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: t(
                  `ProccessingBackend:Scripts:${
                    scripts[
                      scripts.findIndex(
                        (el) => el["scriptId"] == selectedScriptId
                      )
                    ].scriptId
                  }:description`
                ).replace(/<\/br>/g, "\n"),
              }}
            ></Typography>
          </div>
        )}
      <StyledPaper>
        <LeftContainer>
          <StyledTextField
            size="small"
            id="name"
            label="Name"
            variant="filled"
            value={name}
            onChange={handleChange}
            required={true}
          />
          <StyledTextField
            size="small"
            id="description"
            label={t("Proccessing:Description")}
            value={description}
            onChange={handleChange}
            variant="filled"
            multiline
            minRows={5}
            required={true}
          />
        </LeftContainer>
        <RightContainer>
          {parametersLoaded && parameters.length == 0 && (
            <Typography
              component="h4"
              style={{ fontWeight: "500", margin: "20px 0 0 20px" }}
            >
              {t("Proccessing:NoParamsSelected")}
            </Typography>
          )}
          {parametersLoaded && parameters.length > 0 && (
            <NewParametersSetter
              parameters={parameters}
              onParametersValuesUpdate={onParametersValuesUpdate}
              dxfFiles={dxfFiles}
              csvFiles={csvFiles}
              pointCloudFiles={pointCloudFiles}
            />
          )}
        </RightContainer>
      </StyledPaper>
    </React.Fragment>
  );
}

//Styling
const StyledPaper = styled(Paper)`
  display: flex;
  width: 90%;
`;

const LeftContainer = styled.div`
  height: 100%;
  width: 40%;
  border-right: 1px solid black;
  padding: 20px;
  box-sizing: border-box;
`;

const RightContainer = styled.div`
  heihgt: 100%;
  flex-grow: 1;
  max-width: 60%;
`;

const StyledSelectorsContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin: 20px 0 10px 0;
  line-height: 1;
  padding: 5px 25px;
`;
//Styling

export default ProcessingInitiation;
