import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

function DxfInput({ parameter, onValueUpdate, dxfFiles }) {

  const { t } = useTranslation();

  const handleChange = (event) => {
    const tv = parseInt(event.target.value);
    onValueUpdate({
      payload: {
        parameterId: parameter.parameterId,
        value: tv,
        selected: true,
      },
    });
  };

  return (
    <>
      {dxfFiles.length > 0 ? (
        <FormControl>
          <InputLabel htmlFor="10">{t(`ProccessingBackend:Parameters:${parameter.parameterId}:parameterName`)}</InputLabel>
          <Select
            name={parameter.parameterId}
            style={{ minWidth: "220px" }}
            onChange={handleChange}
          >
            {dxfFiles.map((file) => (
              <MenuItem value={file.fileId}>{file.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          disabled
          defaultValue={t("General:NoFilesFound")}
          size="small"
        />
      )}
    </>
  );
}

export default DxfInput;
