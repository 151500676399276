import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Wrapper from "./Wrapper";
import authStore from "./authStore";

const ResendCode: FC = () => {
  const { t } = useTranslation();

  const { values, inProgress, error, success, cognitoUser } = authStore;

  const history = useHistory();

  useEffect(() => {
    if (cognitoUser) {
      history.push("/");
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setEmail(e.target.value);
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.resendCode();
  };

  return (
    <Wrapper>
      <>
        <Typography component="h1" variant="h5">
          {t("Auth:ResendConfirmationCode")}
        </Typography>
        {success.resendCode ? (
          <Alert severity="success" sx={alertStyles}>
            <AlertTitle>{t("Auth:Success")}</AlertTitle>
            {t("Auth:SuccessResendCodeMessage")}
          </Alert>
        ) : (
          <Typography sx={messageStyles} variant="subtitle1">
            {t("Auth:EnterEmailMessage")}
          </Typography>
        )}
        <Typography variant="body1" color="error">
          {error}
        </Typography>
        <Box component="form" onSubmit={handleSubmitForm} sx={formStyles}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label={t("Auth:Email")}
                type="email"
                name="email"
                autoComplete="email"
                value={values.email}
                onChange={handleEmailChange}
                color="secondary"
                sx={textFieldsStyles}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            color="secondary"
            variant="contained"
            sx={buttonStyles}
            disabled={inProgress}
            disableElevation
          >
            {t("Auth:ResendCode")}
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/register/confirm" variant="body2" underline="hover">
                {t("Auth:GoBackToConfirm")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </>
    </Wrapper>
  );
};

const buttonStyles = {
  mt: 3, 
  mb: 2, 
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608", 
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608", 
      borderColor: "#ec6608", 
      "& legend": {
        color: "#ec6608", 
      },
    },
  },
};

const formStyles = { mt: 3, width: "100%" };

const messageStyles = { mt: 2, color: "primary.light" };

const alertStyles = { mt: 2, width: "100%" };

export default observer(ResendCode);
