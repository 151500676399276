import axios from "axios";
import config from "../config";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const controller = {
  getSetupIntentClientSecret: async function () {
    const response = await axios.get(`${prefix}/payments/setup-intent`);
    return response.data.data;
  },
  getTest: async function () {
    const response = await axios.get(`${prefix}/payments/test`);
    return response.data;
  },
};

export default controller;
