import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Wrapper from "./Wrapper";
import ForceChangePassword from "./ForceChangePassword";
import authStore from "./authStore";

const Login: FC = () => {
  const { t } = useTranslation();

  const { values, isFirstLogin, inProgress, error, cognitoUser } = authStore;

  const history = useHistory();

  useEffect(() => {
    if (cognitoUser) {
      history.push("/");
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setPassword(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.login();
  };

  return (
    <Wrapper>
      <>
        {isFirstLogin ? (
          <ForceChangePassword />
        ) : (
          <>
            <Typography component="h1" variant="h5">
              {t("Auth:Login")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 1 }}
              component="div"
              color="error"
            >
              {error}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={styles}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label={t("Auth:Email")}
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleEmailChange}
                    color="secondary"
                    sx={textFieldsStyles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label={t("Auth:PasswordLabel")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handlePasswordChange}
                    color="secondary"
                    sx={textFieldsStyles}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                color="secondary"
                variant="contained"
                sx={buttonStyles}
                disabled={inProgress}
                disableElevation
              >
                {t("Auth:Login")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="/login/new-password"
                    variant="body2"
                    underline="hover"
                  >
                    {t("Auth:ForgotPassword")}?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2" underline="hover">
                    {t("Auth:SignUpMessage")}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
    </Wrapper>
  );
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608",
      borderColor: "#ec6608",
      "& legend": {
        color: "#ec6608",
      },
    },
  },
};

const buttonStyles = {
  mt: 3,
  mb: 2,
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const styles = { mt: 3, width: "100%" };

export default observer(Login);
