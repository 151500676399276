import axios from "axios";
import config from "../config";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const controller = {
  getInfo: async function (projectId) {
    const response = await axios.get(`${prefix}/projects/${projectId}`);
    return response.data.data.projectName;
  },

  getProjects: async function () {
    const response = await axios.get(`${prefix}/projects`);
    const projects = response.data.data;
    if (projects.length === 0) {
      return projects;
    }
    return projects;
  },

  createProject: async function (name, description) {
    const response = await axios.post(`${prefix}/projects`, {
      projectName: name,
      projectDescription: description,
    });
    return response.data.data;
  },

  deleteProject: async function (projectId) {
    await axios.delete(`${prefix}/projects/${projectId}`);
  },

  editProject: async function (projectId, project) {
    await axios.put(`${prefix}/projects/${projectId}`, project);
  },
};

export default controller;
