import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import styled from "styled-components";

function UploadsProgress({ fileUploads }) {
  return (
    <Wrapper>
      {Object.values(fileUploads).map((val, index) => (
        <ItemWrapper key={index}>
          <FlexBlock>
            {val.progress === "done" ? (
              <DoneIcon />
            ) : (
              <FileIcon fontSize="small" />
            )}
            <FileName>{val.fileName}</FileName>
          </FlexBlock>
          {val.progress !== "done" && (
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={val.progress}
                />
              </Box>
              <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                  {val.progress}%
                </Typography>
              </Box>
            </Box>
          )}
        </ItemWrapper>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 90%;
  padding: 16px 16px 4px;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
`;

const ItemWrapper = styled.div`
  margin-bottom: 12px;
`;

const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  margin-right: 8px;
`;

const DoneIcon = styled(CheckCircleOutlineIcon)`
  fill: #12b800;
  margin: 0 8px 0 -4px;
`;

const FileName = styled.div`
  word-break: break-all;
`;

const FlexBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export default UploadsProgress;
