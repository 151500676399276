import axios from "axios";
import config from "../config";
import FileDownload from "js-file-download";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const controller = {
  getHistory: async function (id) {
    const response = await axios.get(`${prefix}/processings/${id}`);
    let history = response.data.data;
    return history;
  },

  downloadFile: async function (projectId, outputFileId, outputFileName) {
    const response = await axios.get(
      `${prefix}/projects/files/${projectId}/${outputFileId}/${outputFileId}`,
      { responseType: "blob" }
    );
    FileDownload(response.data, outputFileName);
  },

  getProcessing: async function (projectId, processingId) {
    const response = await axios.get(
      `${prefix}/processings/${projectId}/${processingId}`
    );
    return response.data.data;
  },

  abortProcessing: async function (projectId, processingId) {
    await axios.put(`${prefix}/processings/${projectId}/${processingId}`);
  },
};

export default controller;
