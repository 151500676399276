import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

function CsvInput({ parameter, onValueUpdate, csvFiles }) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const tv = parseInt(event.target.value);
    onValueUpdate({
      payload: {
        parameterId: parameter.parameterId,
        value: tv,
        selected: true,
      },
    });
  };

  return (
    <>
      {csvFiles && csvFiles.length > 0 ? (
        <FormControl>
          <InputLabel htmlFor="10">{parameter.parameterName}</InputLabel>
          <Select
            name={parameter.parameterId}
            style={{ minWidth: "220px" }}
            onChange={handleChange}
            defaultValue=""
          >
            {csvFiles.map((file, index) => (
              <MenuItem key={index} value={file.fileId}>{file.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          disabled
          defaultValue={t(`General:NoFilesFound`)}
          size="small"
        />
      )}
    </>
  );
}

export default CsvInput;
