import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfoBar from "./InfoBar";
import SupportPackage from "./SupportPackage";
import controller from "../../controllers/contingentController";
import utils from "../../utils/utils";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardHeader: {
    backgroundColor: "#dcdcdc",
    height: "100%",
  },
  cardContent: {
    marginBottom: "52px",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(1),
  },
  p0: {
    padding: 0,
  },
  grid: {
    width: "100%",
    margin: 0,
  },
  iconWrapper: {
    width: "100%",
    marginBottom: "-35px",
  },
}));

function Contingent() {
  const classes = useStyles();
  const Button = styled(MuiButton)(spacing);

  const [allContingents, setAllContingents] = useState([]);
  const [customerType, setCustomerType] = useState("");
  const [supportPackageSwitch, setSupportPackageSwitch] = useState(false);
  const [isActivated, setIsActivated] = useState();
  const [updateInfo, setUpdateInfo] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    getAllContingents();
  }, [updateInfo]);

  const getAllContingents = async () => {
    const AllContingents = await controller.getAllContingents();
    matchContingent(AllContingents.contingents, AllContingents.supportPackages);
    setCustomerType(AllContingents.userInfo.name);
    setIsActivated(AllContingents.userInfo.isActivated);
  };

  const matchContingent = (contingents = [], supportPackages) => {
    const matchContingent = contingents.map((contingent) => {
      const supportPackage = supportPackages.filter((supportPackage) => {
        if (supportPackage) {
          if (supportPackage.supportPackageId === contingent.supportPackageId) {
            return supportPackage;
          }
        }
      })[0];
      return Object.assign({}, { contingent }, { supportPackage });
    });
    setAllContingents(matchContingent);
  };

  const switchUpdate = (name, checked) => {
    setSupportPackageSwitch({
      ...supportPackageSwitch,
      [name]: checked,
    });
  };

  const checkSwitch = (contingent, supportPackage) => {
    if (
      supportPackage &&
      supportPackageSwitch[
        supportPackage.supportPackageId + contingent.contingentId
      ]
    ) {
      return true;
    }
    return false;
  };

  const onBuyClick = (contingent, supportPackage) => {
    if (
      window.confirm(
        `${t("Contingents:ContingentOrder")} ${contingent.numberOfGB} ${t(
          "Contingents:AddSpace"
        )} ${
          checkSwitch(contingent, supportPackage)
            ? t("Contingents:AndSP")
            : t("Contingents:For")
        } ${
          checkSwitch(contingent, supportPackage)
            ? utils.formatPrice(contingent.amount + supportPackage.amount)
            : utils.formatPrice(contingent.amount)
        }€ ${t("Contingents:Charge")}`
      )
    ) {
      const checkSupportPack = () => {
        if (checkSwitch(contingent, supportPackage)) {
          return supportPackage.supportPackageI;
        }
        return null;
      };
      buyContingent(contingent.contingentId, checkSupportPack());
    }
  };

  const buyContingent = async (contingentId, supportPackageId) => {
    const BuyContingent = await controller.buyContingent(
      contingentId,
      supportPackageId
    );
    if (BuyContingent) {
      setUpdateInfo(!updateInfo);
    }
  };

  return (
    <>
      <InfoBar updateInfo={updateInfo} />
      {customerType !== "Standard" && (
        <Container
          maxWidth={allContingents.length === 4 ? "lg" : "md"}
          className={classes.p0}
        >
          <Grid
            container
            spacing={allContingents.length === 4 ? 3 : 5}
            alignItems="stretch"
            justifyContent="center"
            className={classes.grid}
          >
            {allContingents.map((val) => (
              <Grid
                item
                key={val.contingent.name}
                xs={12}
                sm={6}
                md={allContingents.length === 4 ? 3 : 4}
              >
                <Card className={classes.card}>
                  <CardHeader
                    title={val.contingent.name}
                    titleTypographyProps={{ align: "center" }}
                    className={classes.cardHeader}
                  />
                  <CardContent
                    align="center"
                    className={
                      !val.supportPackage ? classes.cardContent : undefined
                    }
                  >
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h3"
                        variant="h4"
                        color="textPrimary"
                      >
                        {checkSwitch(val.contingent, val.supportPackage)
                          ? `€${utils.formatPrice(
                              val.contingent.amount + val.supportPackage.amount
                            )}`
                          : `€${utils.formatPrice(val.contingent.amount)}`}
                      </Typography>
                    </div>
                    <Typography variant="subtitle1">
                      {val.contingent.numberOfGB} Gigabyte
                    </Typography>
                  </CardContent>
                  {val.supportPackage && (
                    <SupportPackage
                      supportPackage={val.supportPackage}
                      contingentId={val.contingent.contingentId}
                      switchUpdate={switchUpdate}
                    />
                  )}
                  <CardActions>
                    {isActivated ? (
                      <Button
                        mb={1}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                          onBuyClick(val.contingent, val.supportPackage)
                        }
                      >
                        {t("Contingents:Buy")}
                      </Button>
                    ) : (
                      <Typography variant="body2" align="center">
                        {t("Contingents:ContactSupportToActivate")}
                      </Typography>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default Contingent;
