import React from "react";
import Typography from "@material-ui/core/Typography";

function AGBs() {
  return (
    <div>
      <Typography
        component="h4"
        variant="h4"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Allgemeine Geschäftsbedingungen für Cloud-Services
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        SOFTWARE AS A SERVICE (SAAS)
      </Typography>
      <Typography paragraph variant="body1">
        vigram GmbH (Stand: 07/2021) für gewerbliche Kunden
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 1 Geltungsbereich
      </Typography>
      <Typography paragraph variant="body1">
        (1) Diese allgemeinen Geschäftsbedingungen (AGB) gelten für alle
        Rechtsgeschäfte im Zusammenhang mit der Überlassung von Cloud-Services /
        Software as a Service.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Der Anbieter stellt dem Kunden während der Laufzeit dieses Vertrages
        die Nutzung der Software "viPoint® Service" zu eigenen Zwecken zur
        Verfügung.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Zugriff und Nutzung der auf Servern des Anbieters gespeicherter
        Software erfolgen über eine Internetverbindung durch die Verwendung
        eines Internet-Browsers. Die Software ist über folgende Webseite
        erreichbar: www.vipoint.eu
      </Typography>
      <Typography paragraph variant="body1">
        (4) Zu den wesentlichen vertraglichen Funktionen der Software gehören
        Folgende:
      </Typography>
      <Typography paragraph variant="body1">
        Bild- und Punktewolkenverarbeitungsservice, Aufbereitung, Optimierungen,
        Klassifizierungen, Segmentierungen und Modellierungen.
      </Typography>
      <Typography paragraph variant="body1">
        (5) Der Anbieter bietet dem Kunden die Software stets in der aktuellen
        Version an.
      </Typography>
      <Typography paragraph variant="body1">
        (6) Der Anbieter hat den Kunden spätestens 2 Tage vor jedem Update von
        der Aktualisierung der Software zu unterrichten. Die Aktualisierung der
        Software hat nur dann zu erfolgen, wenn diese dem Kunden zumutbar ist.
        Die Aktualisierung der Software ist dem Kunden in folgendem Zeitraum
        zumutbar:
      </Typography>
      <Typography paragraph variant="body1">
        Wochenende Samstag 0 Uhr bis Sonntag 24 Uhr
      </Typography>
      <Typography paragraph variant="body1">
        (7) Während des Aktualisierungsvorgangs wird der Anbieter von seiner
        Vertragspflicht aus § 1 Abs. 2 dieses Vertrages befreit.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 2 Beginn und Laufzeit des Nutzungsverhältnisses
      </Typography>
      <Typography paragraph variant="body1">
        (1) Dieser Vertrag tritt mit Unterzeichnung in Kraft und wird für das
        laufende Jahr und 12 weitere Monate abgeschlossen. Die anfänglich
        vereinbarte Vertragslaufzeit verlängert sich jeweils automatisch um die
        Dauer von zwölf Monaten, wenn sie nicht von einer Partei mit einer Frist
        von drei Monaten zum jeweiligen Laufzeitende gekündigt wird.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Die außerordentliche Kündigung aus wichtigem Grund bleibt beiden
        Parteien vorbehalten, sofern die erforderlichen Voraussetzungen erfüllt
        sind. Folgende Umstände begründen einen wichtigen Grund:
      </Typography>
      <Typography paragraph variant="body1">
        Einstellung des gesamten Services oder Teilen daraus, welche nicht oder
        weniger als 10% des Gesamtumfangs genutzt werden.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Eine wirksame Kündigung bedarf stets der Schriftform.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 3 Vergütung
      </Typography>
      <Typography paragraph variant="body1">
        (1) Im Gegenzug zu den Leistungen des Anbieters hat der Kunde eine
        entsprechende Vergütung zu erbringen.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Soweit nicht anders vereinbart, schuldet der Kunde die Vergütung
        nach den zum Zeitpunkt des Vertragsabschlusses gültigen Preisangaben
        zuzüglich Umsatzsteuer in gesetzlicher Höhe.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Soweit nicht anders vereinbart, sind Vergütungen abhängig vom
        genutzten Volumen und der max. Laufzeit von 12 Monaten je Kontingent ab
        Kaufdatum jährlich im Voraus zahlbar.
      </Typography>
      <Typography paragraph variant="body1">
        (4) Der Anbieter hat dem Kunden für den vereinbarten Abrechnungszeitraum
        eine Rechnung zu stellen, die an diesen per E-Mail zu senden ist.
      </Typography>
      <Typography paragraph variant="body1">
        (5) Der Kunde kann erst nach Zahlungseingang den WEB-Service
        viPoint®voll umfänglich nutzen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 4 Verfügbarkeit der Software und Pflichten des Kunden
      </Typography>
      <Typography paragraph variant="body1">
        (1) Der Anbieter weist den Kunden darauf hin, dass er keine 100%ige
        Verfügbarkeit der Software garantieren kann, wenn Einschränkungen oder
        Beeinträchtigungen entstehen, die außerhalb des Einflussbereichs des
        Anbieters stehen. Der Anbieter kann auch außerhalb der Fälle des § 1
        Abs. 6 und 7 dieses Vertrages mit Zustimmung des Kunden für einen
        bestimmtem Zeitraum von seiner Leistungspflicht befreit werden.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Der Kunde ist verpflichtet, den Anbieter unverzüglich schriftlich
        (per E-Mail) darüber zu unterrichten, sobald die Software nicht
        verfügbar ist.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 5 Nutzungsrecht des Kunden, Zugriffsberechtigung
      </Typography>
      <Typography paragraph variant="body1">
        (1) Der Kunde erhält an der Software ein auf die Laufzeit des
        vorliegenden Vertrages beschränktes einfaches Nutzungsrecht. Soweit
        nicht ausdrücklich anders vereinbart, ist es dem Kunden nicht gestattet,
        Dritten hieran Nutzungsrechte einzuräumen oder Nutzungsrechte zu
        veräußern.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Es erfolgt keine körperliche Überlassung der Software. Die Software
        bleibt jederzeit auf dem Server des Anbieters.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Die Nutzung der Software wird festgelegten Nutzern bzw.
        Nutzergruppen gestattet, die dem Anbieter als Liste zu übermitteln sind.
      </Typography>
      <Typography paragraph variant="body1">
        (4) Die vereinbarten Nutzer erhalten eine Zugriffsberechtigung in Form
        eines Benutzernamen und eines Passworts. Benutzername und Passwort
        können vom jeweiligen Nutzer geändert werden, wobei folgende
        Voraussetzungen beachtet werden müssen:
      </Typography>
      <Typography paragraph variant="body1">
        Der Benutzername muss einen Hinweis auf die Identität des Nutzers geben.
        Allgemeine Emailadressen wie info@, service@, etc. sind nicht erlaubt.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 6 Hilfestellung
      </Typography>
      <Typography paragraph variant="body1">
        Der Anbieter stellt dem Kunden eine Wissensdatenbank zur Verfügung.
        Diese wird laufend gepflegt und mit Neuerungen versehen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 7 Support
      </Typography>
      <Typography paragraph variant="body1">
        Der Anbieter stellt dem Kunden zur Beseitigung von technischen Störungen
        und Behebung von Fehlern, die im Rahmen der Nutzung der Software
        aufkommen, per E-Mail einen Kundendienst zur Verfügung. Der Kundendienst
        des Anbieters ist Montags bis Freitags von 10 bis 12 Uhr und 14 bis 16
        Uhr erreichbar, und zwar folgendermaßen:
      </Typography>
      <Typography paragraph variant="body1">
        E-Mail: Support@vigram.com
      </Typography>
      <Typography paragraph variant="body1" style={{ fontWeight: 500 }}>
        Die Nutzung des Supports ist nur mit einer lizensierten Emailadresse
        gestattet.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 8 Mängelansprüche
      </Typography>
      <Typography paragraph variant="body1">
        (1) Der Anbieter haftet für Mängel der Vertragsleistungen.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Ansprüche nach § 536a BGB, insbesondere die verschuldensunabhängige
        Garantiehaftung und das Selbstvornahmerecht betreffend, sind
        ausgeschlossen.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Ein Sachmangel liegt vor, wenn die Software nicht die vertraglich
        vereinbarte Beschaffenheit aufweist oder sich nicht für die vertraglich
        vorausgesetzte Verwendung eignet. Unerhebliche Abweichungen stellen
        keinen Mangel dar.
      </Typography>
      <Typography paragraph variant="body1">
        (4) Der Kunde ist verpflichtet, den Anbieter unverzüglich schriftlich
        (per Brief oder per E-Mail) von aufgetretenen Mängeln zu unterrichten.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 9 Haftung
      </Typography>
      <Typography paragraph variant="body1">
        (1) Die Vertragsparteien haften für Vorsatz und grobe Fahrlässigkeit.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Der Anbieter haftet für die Verletzung wesentlicher
        Vertragspflichten (sog. Kardinalspflichten). Dabei handelt es sich um
        solche vertraglichen Pflichten, deren Erfüllung den Vertrag so
        wesentlich prägt, als dass deren Verletzung eine Gefährdung der
        Erreichung des Vertragszwecks darstellt, und auf deren Einhaltung der
        Kunde vertrauen darf. Soweit die Kardinalspflichten fahrlässig verletzt
        wurden, ist der Schadensersatzanspruch des Kunden begrenzt auf den
        vertragstypisch vorhersehbaren Schaden, maximal jedoch auf einen Betrag
        in Höhe von 10.000 € (in Worten: zehntausend Euro).s
      </Typography>
      <Typography paragraph variant="body1">
        (3) Der Anbieter haftet außerdem gemäß den gesetzlichen Bestimmungen
        nach den Vorschriften des Produkthaftungsgesetzes und für Schäden, die
        durch die Verletzung des Lebens, des Körpers oder der Gesundheit des
        Kunden entstanden sind.
      </Typography>
      <Typography paragraph variant="body1">
        (4) Der Anbieter haftet nicht für Schäden seiner Erfüllungsgehilfen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 10 Haftungsbeschränkungen
      </Typography>
      <Typography paragraph variant="body1">
        Der Anbieter haftet nicht für den Verlust von Kundendaten, wenn der
        Schaden darauf beruht, dass es der Kunde unterlassen hat,
        Datensicherungen durchzuführen und dadurch sicherzustellen, dass
        verloren gegangene Kundendaten wiederhergestellt werden können. Der
        Kunde ist allein verantwortlich für die regelmäßige und vollständige
        Datensicherung und hat diese selbst oder durch Dritte durchzuführen bzw.
        durchführen zu lassen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 11 Datenspeicherung, Datenschutz
      </Typography>
      <Typography paragraph variant="body1">
        (1) Beide Vertragspartner sind dazu verpflichtet, zum Schutz
        personenbezogener Daten die gesetzlichen Datenschutzbestimmungen,
        insbesondere die Datenschutzgrundverordnung (DSGVO), einzuhalten.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Der Kunde räumt dem Anbieter das Recht ein, die durch die Nutzung
        der Software entstehenden Daten auf dem vom Anbieter verantworteten
        Server zur Erfüllung seiner Vertragspflichten zu speichern und diese für
        die Datensicherung auf einem Backup-Server (Cloud) zu vervielfältigen,
        sofern dies zum Zweck der Vertragserfüllung erforderlich ist. Dabei ist
        der Anbieter frei in der Wahl des Cloudanbieters und Örtlichkeit, an
        welcher die Daten gespeichert werden.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 12 Herausgabe und Löschung von Daten
      </Typography>
      <Typography paragraph variant="body1">
        (1) Nach Upload der Daten hat der Anbieter 14 Tage Zeit diese zu
        verarbeiten. Die bereitgestellten modifizierten Datensätze werden nach
        Fertigstellung für weitere 14 Tage zum Download bereitgestellt.
        Anschließend werden diese Daten unwiderruflich gelöscht.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Der Anbieter hat innerhalb von 30 Tage nach Beendigung des
        Vertragsverhältnisses sämtliche gespeicherte Daten des Kunden auf dem
        eigenen Server vollständig zu löschen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 13 Geheimhaltung, Vertraulichkeit
      </Typography>
      <Typography paragraph variant="body1">
        (1) Die Parteien sind verpflichtet, alle ihnen im Zusammenhang mit
        diesem Vertrag bekannt gewordenen vertraulichen Informationen über die
        jeweils andere Partei dauerhaft geheim zu halten, nicht an Dritte
        weiterzugeben, aufzuzeichnen oder in anderer Weise zu verwerten, sofern
        die jeweils andere Partei der Offenlegung oder Verwendung nicht
        ausdrücklich und schriftlich zugestimmt hat oder die Informationen
        aufgrund Gesetzes, Gerichtsentscheidung oder Verwaltungsentscheidung
        offengelegt werden müssen. Liegt keine solche Zustimmung oder
        Offenlegung vor, sind die bekannt gewordenen Informationen nur zur
        Durchführung dieses Vertrages zu verwenden.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Der Kunde ist insbesondere zur Geheimhaltung hinsichtlich aller
        Inhalte der Software verpflichtet. Der Kunde darf die Zugriffsdaten
        (Benutzernamen und Passwörter) nicht an Dritte weitergeben.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Keine vertraulichen Informationen im Sinne des § 13 Abs. 1 dieses
        Vertrages sind Folgende:
      </Typography>
      <Typography paragraph component="div" variant="body1">
        <ul>
          <li>
            Informationen, die der anderen Partei bereits zuvor bekannt waren.
          </li>
          <li>Informationen, die allgemein bekannt sind.</li>
          <li>
            Informationen, die der anderen Partei von einem Dritten offenbart
            wurden, ohne dass dieser dadurch eine Vertraulichkeitsverpflichtung
            verletzt hat.
          </li>
        </ul>
      </Typography>
      <Typography paragraph variant="body1">
        (4) Die Verpflichtungen aus diesem Paragraphen sind auch auf den
        Zeitraum nach Beendigung des Vertragsverhältnisses anzuwenden.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        § 14 Schlussbestimmungen
      </Typography>
      <Typography paragraph variant="body1">
        (1) Rechtserhebliche Erklärungen und Anzeigen, die nach Vertragsschluss
        abzugeben sind, bedürfen zu ihrer Wirksamkeit der Schriftform.
      </Typography>
      <Typography paragraph variant="body1">
        (2) Diese Vertragsbedingungen gelten ausschließlich. Anderweitigen
        Geschäftsbedingungen des Anbieters, des Kunden oder Dritter wird hier
        hiermit ausdrücklich widersprochen.
      </Typography>
      <Typography paragraph variant="body1">
        (3) Mündliche Nebenabreden bestehen nicht. Änderungen, Ergänzungen und
        die Aufhebung dieses Vertrages bedürfen der Schriftform. Dies gilt auch
        für die Änderung dieser Schriftformklausel selbst.
      </Typography>
      <Typography paragraph variant="body1">
        (4) Sollten einzelne Bestimmungen dieses Vertrages ganz oder teilweise
        unwirksam sein oder nach Vertragsschluss unwirksam werden, so wird die
        Wirksamkeit der übrigen Bestimmungen hierdurch nicht berührt. Die
        Vertragsparteien sind in diesem Fall verpflichtet, über eine wirksame
        und zumutbare Ersatzregelung zu verhandeln, die dem mit der unwirksamen
        Bestimmung verfolgten Sinn und Zweck möglichst nahe kommt. Dies gilt
        auch im Falle einer Vertragslücke.
      </Typography>
      <Typography paragraph variant="body1">
        (5) Anhänge zu diesem Vertrag sind Bestandteil dieses Vertrags.
      </Typography>
      <Typography paragraph variant="body1">
        (6) Dieser Vertrag unterliegt ausschließlich dem Recht der
        Bundesrepublik Deutschland.
      </Typography>
      <Typography paragraph variant="body1">
        (7) Der Gerichtsstand für alle Streitigkeiten aus diesem Vertrag
        bestimmt sich nach den maßgeblichen Regeln der ZPO.
      </Typography>
    </div>
  );
}

export default AGBs;
