import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import styled from "styled-components";

const StyledLink = styled(Link)`
  margin-left: 5px;
`;

const FirstBlock = styled.div`
  margin-bottom: 60px;
`;

export default function DataProtection() {
  return (
    <div>
      <FirstBlock>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
        align="center"
      >
        Datenschutzerklärung viPoint Services
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Zweck
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        In der Datenschutzerklärung erfahren Sie, wie wir mit Ihren Daten
        umgehen. Welche Daten wir an Dritte weitergeben und zu welchem Zweck.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Hinweis
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Die Formulierungen beziehen sich auf den gesamten viPoint Service
        Bereich und umfassen damit alle Domains und Subdomains. Wird ein
        Missbrauch oder ein Verdacht des Missbrauchs festgestellt, ist dieser
        umgehend dem Support zu melden und exakt zu schildern, wie es dazu
        gekommen ist.
      </Typography>
      </FirstBlock>
      <Typography component="h4" variant="h4" paragraph>
        Datenschutzerklärung
      </Typography>
      <Typography component="h5" variant="h5" paragraph>
        1. Datenschutz auf einen Blick
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Allgemeine Hinweise
      </Typography>
      <Typography paragraph variant="body1">
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Datenerfassung auf dieser Website
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
      </Typography>
      <Typography paragraph variant="body1">
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
        zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Wie erfassen wir Ihre Daten?
      </Typography>
      <Typography paragraph variant="body1">
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </Typography>
      <Typography paragraph variant="body1">
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie diese Website betreten.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Wofür nutzen wir Ihre Daten?
      </Typography>
      <Typography paragraph variant="body1">
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Welche Rechte haben Sie bezüglich Ihrer Daten?
      </Typography>
      <Typography paragraph variant="body1">
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </Typography>
      <Typography paragraph variant="body1">
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit an uns wenden
      </Typography>
      <Typography component="h5" variant="h5" paragraph>
        2. Allgemeine Hinweise und Pflichtinformationen
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Datenschutz
      </Typography>
      <Typography paragraph variant="body1">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </Typography>
      <Typography paragraph variant="body1">
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </Typography>
      <Typography paragraph variant="body1">
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Hinweis zur verantwortlichen Stelle
      </Typography>
      <Typography paragraph variant="body1">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </Typography>
      <Typography paragraph variant="body1">
        vigram GmbH
        <br />
        Jechtinger Straße 21
        <br />
        79111 Freiburg
      </Typography>
      <Typography paragraph variant="body1">
        Telefon: +49 163 726 7707
        <br />
        E-Mail: info@vigramcomlive60eb58c8479d8.cloud.bunnyroute.com
      </Typography>
      <Typography paragraph variant="body1">
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Speicherdauer
      </Typography>
      <Typography paragraph variant="body1">
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
      </Typography>
      <Typography paragraph variant="body1">
        Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
        oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
        diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
        Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
        hin, dass in diesen Ländern kein mit der EU vergleichbares
        Datenschutzniveau garantiert werden kann. Beispielsweise sind
        US-Unternehmen dazu verpflichtet, personenbezogene Daten an
        Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
        hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
        ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
        US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
        auswerten und dauerhaft speichern. Wir haben auf diese
        Verarbeitungstätigkeiten keinen Einfluss.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </Typography>
      <Typography paragraph variant="body1">
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </Typography>
      <Typography paragraph variant="body1">
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </Typography>
      <Typography paragraph variant="body1">
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Beschwerderecht bei der zuständigen Aufsichtsbehörde
      </Typography>
      <Typography paragraph variant="body1">
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Recht auf Datenübertragbarkeit
      </Typography>
      <Typography paragraph variant="body1">
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        SSL- bzw. TLS-Verschlüsselung
      </Typography>
      <Typography paragraph variant="body1">
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </Typography>
      <Typography paragraph variant="body1">
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </Typography>
      <Typography
        component="h5"
        variant="h5"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Auskunft, Löschung und Berichtigung
      </Typography>
      <Typography paragraph variant="body1">
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit an uns wenden.
      </Typography>
      <Typography component="h5" variant="h5" paragraph>
        3. Weitere Funktionen und Angebote unserer Webseite
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Amazon Web Services
      </Typography>
      <Typography paragraph variant="body1">
        Diese Website verwendet Dienste von Amazon Web Services (AWS) Inc., mit
        Sitz in 410 Terry Avenue North Seattle WA 98109, USA. AWS unterstützt
        unsere Website durch cloudbasierte Auslieferung von Website-Inhalten.
        Dabei können auch Informationen wie Ihre (anonymisierte) IP und
        Besuchsdauer übertragen werden. Wir haben zu diesem Zweck einen
        DSGVO-konformen Zusatz zur Datenverarbeitung (Data Processing Addendum)
        mit AWS abgeschlossen. Die personenbezogenen Daten werden unter dem
        EU-US Privacy Shield auf Grundlage des Angemessenheitsbeschlusses der
        Europäischen Kommission in die USA übermittelt. Das Zertifikat können
        Sie
        <StyledLink
          href="https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&status=Active"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          hier
        </StyledLink>
        abrufen. Weitere Informationen dazu und zu AWS Datenschutzmaßnahmen
        finden Sie hier:
        <StyledLink
          href="https://aws.amazon.com/de/compliance/eu-data-protection/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://aws.amazon.com/de/compliance/eu-data-protection/
        </StyledLink>
        .
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Stripe
      </Typography>
      <Typography paragraph variant="body1">
        Wir bieten die Möglichkeit, den Zahlungsvorgang über den
        Zahlungsdienstleister Stripe, ℅ Legal Process, 510,Townsend St., San
        Francisco, CA 94103 (Stripe) abzuwickeln. Dies entspricht unserem
        berechtigten Interesse, eine effiziente und sichere Zahlungsmethode
        anzubieten (Art. 6 Abs. 1 lit. f DSGVO). In dem Zusammenhang geben wir
        folgende Daten an Stripe weiter, soweit es für die Vertragserfüllung
        erforderlich ist (Art. 6 Abs. 1 lit b. DSGVO).
      </Typography>
      <Typography paragraph variant="body1">
        Name des Karteninhabers
        <br />
        E-Mail-Adresse
        <br />
        Adresse
        <br />
        Kundennummer/Bestellnummer
        <br />
        Bankverbindung
        <br />
        Kreditkartendaten
        <br />
        Gültigkeitsdauer der Kreditkarte
        <br />
        Prüfnummer der Kreditkarte (CVC)
        <br />
        Datum und Uhrzeit der Transaktion
        <br />
        Transaktionssumme
        <br />
        Name des Anbieters
      </Typography>
      <Typography paragraph variant="body1">
        Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder
        gesetzlich noch vertraglich vorgeschrieben. Ohne die Übermittlung Ihrer
        personenbezogenen Daten können wir eine Zahlung über Stripe nicht
        durchführen.
        <br />
        Der Kauf auf Anfrage und Vorkasse ist ebenfalls möglich. Hierfür
        entstehen Gebühren, sowie eine Aufwandspauschale, welche wir in unserem
        Angebot an Sie richten.
      </Typography>
      <Typography paragraph variant="body1">
        Stripe nimmt bei Datenverarbeitungstätigkeiten eine Doppelrolle als
        Verantwortlicher und Auftragsverarbeiter ein. Als Verantwortlicher
        verwendet Stripe Ihre übermittelten Daten zur Erfüllung regulatorischer
        Verpflichtungen. Dies entspricht dem berechtigten Interesse Stripes
        (gem. Art. 6 Abs. 1 lit. f DSGVO) und dient der Vertragsdurchführung
        (gem. Art. 6 Abs. 1 lit. b DSGVO). Wir haben auf diesen Prozess keinen
        Einfluss.
      </Typography>
      <Typography paragraph variant="body1">
        Als Auftragsverarbeiter fungiert Stripe, um Transaktionen innerhalb der
        Zahlungsnetzwerke abschließen zu können. Im Rahmen des
        Auftragsverarbeitungsverhältnisses wird Stripe ausschließlich nach
        unserer Weisung tätig und wurde im Sinne des Art. 28 DSGVO vertraglich
        verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.
      </Typography>
      <Typography paragraph variant="body1">
        Stripe hat Compliance-Maßnahmen für internationale Datenübermittlungen
        umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen
        Stripe personenbezogene Daten von natürlichen Personen in der EU
        verarbeitet. Diese Maßnahmen basieren auf den
        EU-Standardvertragsklauseln (SCCs).
      </Typography>
      <Typography paragraph variant="body1">
        Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten
        gegenüber Stripe finden Sie unter:
        <StyledLink
          href="https://stripe.com/privacy-center/legal"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://stripe.com/privacy-center/legal
        </StyledLink>
        .
      </Typography>
      <Typography paragraph variant="body1">
        Ihre Daten werden von uns bis zum Abschluss der Zahlungsabwicklung
        gespeichert. Dazu zählt auch der Zeitraum der für die Bearbeitung von
        Rückerstattungen, Forderungsmanagement und Betrugsprävention
        erforderlich ist. [Für uns gilt gemäß [§ 147 AO / § 257 HGB] eine
        gesetzliche Aufbewahrungsfrist von 10 Jahren für folgende Dokumente:
        Angebote, Auftragsbestätigungen und Rechnungen.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Zendesk
      </Typography>
      <Typography paragraph variant="body1">
        Wir benutzen zur Bearbeitung von Kundenanfragen das Ticketsystem
        Zendesk, eine Kundenserviceplattform der Zendesk Inc., 989 Market Street
        #300, San Francisco, CA 94102. Hierzu werden notwendige Daten wie z.B.
        Name, Vorname, postalische Adresse, Telefonnummer, E-Mail-Adresse über
        unsere Webseite erfasst, um Ihr Informationsbedürfnis beantworten zu
        können. Zendesk ist zertifizierter Teilnehmer des so genannten „Privacy
        Shield Frameworks“ und erfüllt somit die Mindestvoraussetzungen für eine
        gesetzeskonforme Auftragsdatenverarbeitung. Nähere Informationen zur
        Datenverarbeitung durch Zendesk finden Sie in der Datenschutzerklärung
        von Zendesk unter
        <StyledLink
          href="http://www.zendesk.com/company/privacy"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          http://www.zendesk.com/company/privacy
        </StyledLink>
        . Bei Fragen können Sie sich auch direkt an den Datenschutzbeauftragten
        von Zendesk wenden: privacy@zendesk.com
        <br />
        Wir haben mit Zendesk einen Vertrag zur Auftragsdatenverarbeitung
        abgeschlossen und setzen die strengen Vorgaben der deutschen
        Datenschutzbehörden bei der Nutzung von Zendesk vollständig um.
        Rechtsgrundlage der Verarbeitung mit Zendesk sind, soweit Ihre
        Einwilligung vorliegt, Art. 6 Abs. 1 S.1 Lit a, soweit Ihre Einwilligung
        vorliegt, im übrigen Lit. b, sofern die Bearbeitung Ihrer Anfrage der
        Vorbereitung oder Durchführung einer Vertragsbeziehung gilt sowie Lit.
        f., sofern keine Vertragsbeziehung vorliegt, wobei unser berechtigtes
        Interesse in diesem Fall in der Beantwortung Ihrer Anfrage besteht.
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Adobe Fonts
      </Typography>
      <Typography paragraph variant="body1">
        Diese Website nutzt zur einheitlichen Darstellung bestimmter
        Schriftarten Web Fonts von Adobe. Anbieter ist die Adobe Systems
        Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe).
      </Typography>
      <Typography paragraph variant="body1">
        Beim Aufruf dieser Website lädt Ihr Browser die benötigten Schriftarten
        direkt von Adobe, um sie Ihrem Endgerät korrekt anzeigen zu können.
        Dabei stellt Ihr Browser eine Verbindung zu den Servern von Adobe in den
        USA her. Hierdurch erlangt Adobe Kenntnis darüber, dass über Ihre
        IP-Adresse diese Website aufgerufen wurde. Bei der Bereitstellung der
        Schriftarten werden nach Aussage von Adobe keine Cookies gespeichert.
      </Typography>
      <Typography paragraph variant="body1">
        Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an der einheitlichen Darstellung des Schriftbildes auf seiner Website.
        Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
        Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
      </Typography>
      <Typography paragraph variant="body1">
        Die Datenübertragung in die USA wird auf die Standardvertragsklauseln
        der EU-Kommission gestützt. Details finden Sie hier:
        <StyledLink
          href="https://www.adobe.com/de/privacy/eudatatransfers.html"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://www.adobe.com/de/privacy/eudatatransfers.html
        </StyledLink>
        .
      </Typography>
      <Typography paragraph variant="body1">
        Nähere Informationen zu Adobe Fonts erhalten Sie unter:
        <StyledLink
          href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://www.adobe.com/de/privacy/policies/adobe-fonts.html
        </StyledLink>
        .
      </Typography>
      <Typography paragraph variant="body1">
        Die Datenschutzerklärung von Adobe finden Sie unter:
        <StyledLink
          href="https://www.adobe.com/de/privacy/policy.html"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          https://www.adobe.com/de/privacy/policy.html
        </StyledLink>
        .
      </Typography>
      <Typography
        component="h6"
        variant="h6"
        paragraph
        style={{ fontWeight: 500 }}
      >
        Cookies Hinweis
      </Typography>
      <Typography paragraph variant="body1">
        Wir verwenden lediglich technische Cookies, welche für die
        Authentifizierung und Session unserer Benutzer notwendig sind. Für diese
        Art von Cookies sind nach geltendem Recht (Bundesgerichtshof zuletzt mit
        Urteil vom 28.05.2020) keine Cookie-Banner notwendig.
      </Typography>
    </div>
  );
}
