import styled from "styled-components";
import dims from "./dims";

export const MyAppContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

//background-color: #c6c6c6;
export const ContentContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin-left: ${(props) =>
    props.open ? dims.drawerOpenedW : dims.drawerClosedW};
  transition: margin-left 0.2s ease;
`;

export const ContentAndNavContainer = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  padding-top: 64px;
`;

export const InnerContainer = styled.div`
  height: 100%;
  width: 95%;
  max-width: 1100px;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  overflow-y: auto;
  display: flex;
  align-items: center;
  padding: 16px 0px 32px;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const CenteringContainer = styled.div`
  height: calc(100vh - 173px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
