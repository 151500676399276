import axios from "axios";
import config from "../config";

const { host, base } = config.api;
const baseUrl = `https://${host}/${base}`;

const makeRequest = async (path) => {
  const response = await axios.get(`${baseUrl}${path}`);
  return response.data.data;
};

const controller = {
  getCategories: async () => makeRequest("/processings/get-categories"),
  getScripts: async (categoryId) =>
    makeRequest(`/processings/get-scripts/${categoryId}`),
  getParameters: async (scriptId) =>
    makeRequest(`/processings/get-parameters/${scriptId}`),
  initiateProcessing: async (data) =>
    axios.post(`${baseUrl}/processings/create-one`, data),
  getDxfFiles: async (projectId) =>
    makeRequest(`/processings/get-input-files/${projectId}`).then(
      ({ dxfFiles }) => dxfFiles
    ),
  getCsvFiles: async (projectId) =>
    makeRequest(`/processings/get-input-files/${projectId}`).then(
      ({ csvFiles }) => csvFiles
    ),
  getPointCloudFiles: async (projectId, fileId) =>
    makeRequest(`/files/${projectId}`).then((files) => {
      return files.filter((file) => {
        const fileExt = file.name.split(".").pop();
        if (file.fileId != fileId)
          return fileExt === "las" || fileExt === "laz";
      });
    }),
};

export default controller;
