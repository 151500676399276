import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { useTranslation } from "react-i18next";

function IntegerInput({ parameter, onValueUpdate }) {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const handleChange = (event) => {
    let val = parseFloat(event.target.value.replace(/,/, "."));
    setValue(event.target.value);
    onValueUpdate({
      payload: {
        parameterId: parameter.parameterId,
        value: val,
        selected: true
      }
    })
  }

  return (
    <TextField
      value={value}
      id={parameter.parameterId} 
      label={t("General:IntegerParameter")}
      variant="outlined" 
      onChange={handleChange}
      helperText={parameter.minValue && `Min: ${parameter.minValue.toFixed(1)}; Max: ${parameter.maxValue}`}
      size='small'
    />
    
  );
}
//error={!isValid[parameter.parameterId]}

export default IntegerInput; 
