import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

function SingleSelectInput({parameter, onValueUpdate}) {
  const [value, setValue] = useState();

  const { t } = useTranslation();

  const handleChange = (event) => {
    const tv = parseInt(event.target.value);
    Object.keys(parameter.dropDownValues).map(parameterId => {
      if (tv == parameterId) {
        onValueUpdate({
          payload: {
            parameterId,
            value: 1.,
            selected: true
          }
        });
      } else {
        onValueUpdate({
          payload: {
            parameterId,
            value: 0.,
            selected: true 
          }
        })
      }
    })
  }

  return (
    <FormControl>
      <InputLabel htmlFor="10">{t(`ProccessingBackend:Parameters:${parameter.parameterId}:parameterName`)}</InputLabel>
      <Select
        defaultValue=""
        name={parameter.parameterId}
        style={{minWidth: "220px"}}
        onChange={handleChange}>
        {Object.keys(parameter.dropDownValues)
        .map(key => (
        <MenuItem key={key} value={key}>{t(`ProccessingBackend:Parameters:${parameter.parameterId}:dropDownValues:${key}`)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SingleSelectInput;
