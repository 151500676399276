import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Wrapper from "./Wrapper";
import authStore from "./authStore";

const Confirm: FC = () => {
  const { t } = useTranslation();

  const { values, inProgress, error, cognitoUser } = authStore;

  const history = useHistory();

  useEffect(() => {
    if (cognitoUser) {
      history.push("/");
    }
  }, []);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setCode(e.target.value);
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.confirmRegistration().then(() => history.replace("/login"));
  };

  return (
    <Wrapper>
      <>
        <Typography component="h1" variant="h5">
          {t("Auth:ConfirmationCode")}
        </Typography>
        <Typography sx={messageStyles} variant="subtitle1">
          {t("Auth:ConfirmSignUpMessage")}
        </Typography>
        <Typography sx={{ mt: 1 }} variant="body1" color="error">
          {error}
        </Typography>
        <Box component="form" onSubmit={handleSubmitForm} sx={formStyles}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="code"
                label={t("Auth:CodeLabel")}
                type="text"
                id="code"
                value={values.code}
                onChange={handleCodeChange}
                color="secondary"
                sx={textFieldsStyles}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            color="secondary"
            variant="contained"
            sx={buttonStyles}
            disabled={inProgress}
            disableElevation
          >
            {t("Auth:Confirm")}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/register/new-code" variant="body2" underline="hover">
                {t("Auth:ResendCode")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </>
    </Wrapper>
  );
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608", 
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608", 
      borderColor: "#ec6608", 
      "& legend": {
        color: "#ec6608", 
      },
    },
  },
};

const buttonStyles = {
  mt: 3, 
  mb: 2, 
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const formStyles = { mt: 3, width: "100%" };

const messageStyles = { mt: 2, color: "primary.light" };

export default observer(Confirm);
