import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Wrapper from "./Wrapper";
import authStore from "./authStore";

const Register: FC = () => {
  const { t } = useTranslation();

  const passwordValidationError = t("Auth:PasswordValidationError");

  const { values, inProgress, isPasswordValid, error, cognitoUser } = authStore;

  const history = useHistory();

  useEffect(() => {
    if (cognitoUser) {
      history.push("/");
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setPassword(e.target.value);
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.validatePassword(values.password);
    authStore.isPasswordValid &&
      authStore.register().then(() => {
        history.replace("/register/confirm");
      });
  };

  return (
    <Wrapper>
      <>
        <Typography component="h1" variant="h5">
          {t("Auth:SignUp")}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1 }}
          component="div"
          color="error"
        >
          {error}
        </Typography>
        <Box component="form" onSubmit={handleSubmitForm} sx={styles}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label={t("Auth:Email")}
                type="email"
                name="email"
                autoComplete="email"
                value={values.email}
                onChange={handleEmailChange}
                color="secondary"
                sx={textFieldsStyles}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!isPasswordValid}
                required
                fullWidth
                name="password"
                label={t("Auth:PasswordLabel")}
                type="password"
                id="password"
                autoComplete="new-password"
                value={values.password}
                onChange={handlePasswordChange}
                helperText={!isPasswordValid && passwordValidationError}
                color="secondary"
                sx={textFieldsStyles}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            color="secondary"
            variant="contained"
            sx={buttonStyles}
            disabled={inProgress}
            disableElevation
          >
            {t("Auth:SignUp")}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2" underline="hover">
                {t("Auth:LoginMessage")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </>
    </Wrapper>
  );
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608", 
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608", 
      borderColor: "#ec6608", 
      "& legend": {
        color: "#ec6608", 
      },
    },
  },
};

const buttonStyles = {
  mt: 3, 
  mb: 2, 
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const styles = { mt: 3, width: "100%" };

export default observer(Register);
