import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";

function MySelector({ entries, label, setSelectedItem }) {
  return (
    <StyledFormControl size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select onChange={setSelectedItem} defaultValue='' label={label}>
        {entries.map((entry) => (
          <MenuItem key={entry.id} value={entry.id}>
            {entry.name}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

//Styling
const StyledFormControl = styled(FormControl)`
  min-width: 140px;
  margin: 20px 20px 10px 0;
`;
//Styling

export default MySelector;
