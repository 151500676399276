import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import controller from "../../controllers/contingentController";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

function InfoBar({ updateInfo }) {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 20,
      borderRadius: 50,
    },
    colorPrimary: {
      backgroundColor: "#dcdcdc",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.main,
    },
  }))(LinearProgress);

  const { t } = useTranslation();

  const [completed, setCompleted] = useState(0);
  const [numberOfGBTotal, setNumberOfGBTotal] = useState(0);
  const [usedSpace, setUsedSpace] = useState(0);
  const [accountCreditTotal, setAccountCreditTotal] = useState(0);

  useEffect(() => {
    getContingentSum();
  }, [updateInfo]);

  const getContingentSum = async () => {
    const ContingentSum = await controller.getContingentSum();
    setAccountCreditTotal(ContingentSum[0].accountCreditTotal);
    setNumberOfGBTotal(ContingentSum[0].numberOfGBTotal);
    setUsedSpace(
      (
        ContingentSum[0].numberOfGBTotal - ContingentSum[0].accountCreditTotal
      ).toFixed(2)
    );
    const valueCompleted =
      100 -
      (ContingentSum[0].accountCreditTotal / ContingentSum[0].numberOfGBTotal) *
        100;
    if (valueCompleted > 0) {
      setCompleted(valueCompleted);
    }
  };

  return (
    <>
      <Wrapper>
        <Typography component="h2" variant="h6" color="textPrimary">
          {t("Contingents:YourCredit")}
        </Typography>
        <BarWrapper>
          <BorderLinearProgress variant="determinate" value={completed} />
          <UsedSpace>{`${usedSpace}GB / ${numberOfGBTotal}GB`}</UsedSpace>
        </BarWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
  text-align: center;
`;

const BarWrapper = styled.div`
  position: relative;
  margin: 15px 0 30px;
`;

const UsedSpace = styled.div`
  position: absolute;
  color: #fff;
  font-weight: bold;
  line-height: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export default InfoBar;
