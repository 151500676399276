import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UploadsProgress from "./UploadsProgress";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import MinimizeIcon from "@material-ui/icons/Minimize";
import LaunchIcon from "@material-ui/icons/Launch";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { CompareArrowsOutlined } from "@material-ui/icons";

function UploadsIndicator({ fileUploads, updateFiles, updateFilesState }) {
  const useStyles = makeStyles({
    icon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    gridItem: {
      width: "100%",
    },
  });

  const classes = useStyles();

  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(true);
  const [showUploads, setShowUploads] = useState(true);
  const [changeIcon, setChangeIcon] = useState(false);
  const [fileUploadsArray, setFileUploadsArray] = useState([]);

  const { t } = useTranslation();

  const elements = Object.values(fileUploads);

  useEffect(() => {
    for (let element of elements) {
      const arr = [];
      Object.entries(element).map(([fileName, progress]) => {
        arr.push({ fileName, progress });
        setFileUploadsArray(arr);
      });
      setProgress(arr[arr.length - 1].progress);
    }
  }, [fileUploads]);

  useEffect(() => {
    if (progress === "done") {
      updateFiles(!updateFilesState);
      setChangeIcon(true);
    }
    if (progress === 0) {
      setChangeIcon(false);
      setShowUploads(true);
    }
  }, [progress]);

  const minimizeWindow = () => {
    setShowProgress(false);
  };

  const maximizeWindow = () => {
    setShowProgress(true);
  };

  const closeWindow = () => {
    setShowUploads(false);
  };

  return (
    <>
      {fileUploadsArray.length > 0 && showUploads && (
        <Wrapper>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <Grid item className={classes.gridItem}>
              <Paper>
                <Header>
                  <Typography variant="button">{t("Files:FileUploads")}</Typography>
                  <div>
                    {changeIcon ? (
                      <CloseIcon
                        onClick={closeWindow}
                        fontSize="small"
                        className={classes.icon}
                      />
                    ) : (
                      <>
                        {showProgress ? (
                          <MinimizeIcon
                            onClick={minimizeWindow}
                            fontSize="small"
                            className={classes.icon}
                          />
                        ) : (
                          <LaunchIcon
                            onClick={maximizeWindow}
                            fontSize="small"
                            className={classes.icon}
                          />
                        )}
                      </>
                    )}
                  </div>
                </Header>
                {showProgress && (
                  <UploadsProgress fileUploads={fileUploadsArray} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled(Container)`
  max-width: 362px;
  position: fixed;
  bottom: 80px;
  right: 0;
  z-index: 999;
`;

const Header = styled.div`
  background-color: #dcdcdc;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export default UploadsIndicator;
