import React from "react"
import { ListAltRounded, DonutLarge, Payment } from "@material-ui/icons"
import { List } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import dims from "../dims"
import MyNavListItem from "./MyNavListItem"
import config from "../../config"

function MyDrawer(props) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <MyList
      theme={theme}
      open={props.open}
      component="nav"
      disablePadding={true}
    >
      <MyNavListItem
        icon={<ListAltRounded />}
        text={t("General:MenuProject")}
        open={props.open}
        urlLink={`/${config.react.publicURL}/projects`}
      />

      <MyNavListItem
        icon={<DonutLarge />}
        text={t("General:MenuContingent")}
        open={props.open}
        urlLink={`/${config.react.publicURL}/contingent`}
      />

      {/* <MyNavListItem
        icon={<Payment />}
        text={t("General:MenuSubscription")}
        open={props.open}
        urlLink={`/${config.react.publicURL}/subscription`}
      /> */}
    </MyList>
  )
}

//Styling
const MyList = styled(List)`
  width: ${(props) => (props.open ? dims.drawerOpenedW : dims.drawerClosedW)};
  box-sizing: border-box;
  overflow: hidden;
  transition: width 0.2s ease;
  border-right: ${(props) =>
    props.open ? dims.drawerOpenedBorder : dims.drawerClosedBorder};
  position: fixed;
  padding-top: 15px;
  height: 100%;
`
//Styling

export default MyDrawer
