import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

function Actions({ entry, onAbortClick }) {
  const { t } = useTranslation();
  const [abortDisabled, setAbortDisabled] = useState(false);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {(entry.status === t(`Proccessing:inProgress`) ||
        entry.status === t(`Proccessing:queued`)) && (
        <Tooltip title="Abbrechen">
          <IconButton
            disabled={abortDisabled}
            style={{ padding: "0" }}
            color="primary"
            onClick={() => onAbortClick(entry)}
          >
            <CancelIcon style={{ fill: "red" }} fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}

      {entry.fileId && <CheckCircleOutlineIcon style={{ fill: "#12b800" }} />}
      {!entry.fileId &&
        entry.status !== t(`Proccessing:error`) &&
        entry.status !== t(`Proccessing:canceled`) && (
          <CircularProgress style={{ marginLeft: "4px" }} size={20} />
        )}
    </div>
  );
}

const renderActions = (NNonDelete, entry, NNonEditClick, onAbortClick) => {
  //Not-Needed-onDelete, this argument is not used in this Actions component, but is used in others (Projects, Files)
  return <Actions entry={entry} onAbortClick={onAbortClick} />;
};

export default renderActions;
