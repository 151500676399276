import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useParams } from 'react-router-dom';
import controller from "../../controllers/projectsController.js";
import config from '../../config';

function Actions({ onDelete, entry, onEditClick }) {
  const history = useHistory();

  const { t } = useTranslation();

  const proto = "https:";
  const api = config.api;
  const prefix = `${proto}//${api.host}/${api.base}`;

  const onOpenClick = () => {
    history.push(`/${config.react.publicURL}/projects/${entry.projectId}`);
  }

  const onDeleteClick = async () => {
    if (window.confirm(t("Projects:DeleteConfirm"))) {
      try {
        await controller.deleteProject(entry.projectId);
        onDelete();
      } catch (e) {
        window.alert(t("Projects:MessageWrong"));
      }
    }
  }

  return (
    <div style={{display: "flex", justifyContent: "flex-end"}}>
      <Tooltip title={t("Projects:Open")}>
        <IconButton
          style={{padding: "0"}}
          color="secondary"
          onClick={onOpenClick}>
          <OpenInBrowserIcon fontSize="medium"/>
        </IconButton>
      </Tooltip>

      <Tooltip title={t("Projects:Edit")}>
        <IconButton
          style={{padding: "0"}}
          color="secondary"
          onClick={() => onEditClick(entry)}>
          <EditIcon fontSize="medium"/>
        </IconButton>
      </Tooltip>
      
      <Tooltip title={t("Projects:Delete")}>
        <IconButton
          style={{padding: "0"}}
          color="secondary"
          onClick={onDeleteClick}>
          <DeleteIcon fontSize="medium"/>
        </IconButton>
      </Tooltip>
    </div>
  );
}

const renderActions = (onDelete, entry, onEditClick) => {
  return (
    <Actions 
      onDelete={onDelete}
      entry={entry}
      onEditClick={onEditClick} />   
  );
}

export default renderActions;
