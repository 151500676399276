import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, Switch, Tooltip } from "@material-ui/core";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: "15px",
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "auto",
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  additionalService: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  heading: {
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    fontSize: "12px",
    maxWidth: "224px",
  },
}));

function SupportPackage({ supportPackage, switchUpdate, contingentId }) {
  const classes = useStyles();

  const switchId = supportPackage.supportPackageId + contingentId;
  const [supportPackageSwitch, setSupportPackageSwitch] = useState({
    [switchId]: false,
  });

  const handleChange = (event) => {
    setSupportPackageSwitch({
      [event.target.name]: event.target.checked,
    });
    switchUpdate(event.target.name, event.target.checked);
  };

  return (
    <div className={classes.additionalService}>
      <Tooltip
        title={
          <div className={classes.description}>
            {supportPackage.description}
          </div>
        }
        placement="right"
      >
        <div className={classes.heading}>
          <Typography variant="subtitle2">{supportPackage.name}</Typography>
          <IOSSwitch
            checked={supportPackageSwitch[switchId]}
            onChange={handleChange}
            name={switchId.toString()}
          />
        </div>
      </Tooltip>
    </div>
  );
}

export default SupportPackage;
