import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import mainStore from "../Auth/mainStore";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: localStorage.getItem("i18nextLng") || "en",
    fallbackLng: "en",
    returnEmptyString: false,
    debug: false,
    ns: [
      "Auth",
      "Projects",
      "Contingents",
      "General",
      "Files",
      "Payment",
      "Proccessing",
      "ProccessingBackend",
    ],
    keySeparator: " ",
    backend: {
      loadPath: "/locale/{{lng}}/{{ns}}.json",
    },
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    fallbackNS: "default",
  })
  .then(() => mainStore.setLanguageDefined());

export default i18n;
