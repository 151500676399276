import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import styled from "styled-components";
import projectsController from "../controllers/projectsController";
import filesController from "../controllers/filesController";
import historyController from "../controllers/historyController";
import globalConfig from "../config";

function Breadcrumbs({ crumbs }) {
  const [loaded, setLoaded] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [fileName, setFileName] = useState("");
  const [processingName, setProcessingName] = useState("");
  const history = useHistory();
  const { projectId, fileId, processingId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, [window.location.pathname]);

  const init = async () => {
    if (crumbs.includes("project")) {
      await fetchAndSetProjectName(projectId);
    } else {
      setProjectName("");
    }
    if (crumbs.includes("file")) {
      await fetchAndSetFileName(projectId, fileId);
    } else {
      setFileName("");
    }
    if (crumbs.includes("processing")) {
      await fetchAndSetProcessingName(projectId, processingId);
    } else {
      setProcessingName("");
    }
    setLoaded(true);
  };

  const fetchAndSetProjectName = async (projectId) => {
    const name = await projectsController.getInfo(projectId);
    console.log("Breadcrumbs", name);
    setProjectName(name);
  };

  const fetchAndSetFileName = async (projectId, fileId) => {
    const name = await filesController.getInfo(projectId, fileId);
    console.log("Breadcrumbs", name);
    setFileName(name);
  };

  const fetchAndSetProcessingName = async (projectId, processingId) => {
    const fetchedProcessing = await historyController.getProcessing(
      projectId,
      processingId
    );
    setProcessingName(fetchedProcessing.processing[0].processingName);
  };

  const config = {
    projects: {
      alias: () => {
        return t("General:BreadcrumbsProject");
      },
      link: () => {
        return `${globalConfig.react.publicURL}/projects`;
      },
    },
    project: {
      alias: () => {
        return `${projectName}`;
      },
      link: () => {
        return `${globalConfig.react.publicURL}/projects/${projectId}`;
      },
    },
    file: {
      alias: () => {
        return `${fileName}`;
      },
      link: () => {
        return `${globalConfig.react.publicURL}/projects/${projectId}/${fileId}/processing`;
      },
    },
    history: {
      alias: () => {
        return t("General:BreadcrumbsHistory");
      },
      link: () => {
        return `${globalConfig.react.publicURL}/projects/${projectId}/history`;
      },
    },
    processing: {
      alias: () => {
        return `${processingName}`;
      },
      link: () => {
        return `${globalConfig.react.publicURL}/projects/${projectId}/history/${processingId}`;
      },
    },
    contingent: {
      alias: () => {
        return t("General:BreadcrumbsContingent");
      },
      link: () => {
        return `${globalConfig.react.publicURL}/contingent`;
      },
    },
    imprint: {
      alias: () => {
        return t("General:BreadcrumbsImprint");
      },
      link: () => {
        return `${globalConfig.react.publicURL}/imprint`;
      },
    },
    dataProtection: {
      alias: () => {
        return t("General:BreadcrumbsDataProtection");
      },
      link: () => {
        return `${globalConfig.react.publicURL}/data-protection`;
      },
    },
    agbs: {
      alias: () => {
        return `AGBs`;
      },
      link: () => {
        return `${globalConfig.react.publicURL}/agbs`;
      },
    },
  };

  const goTo = (url) => {
    history.push(url);
  };

  return (
    <React.Fragment>
      {loaded && (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "30px",
            borderBottom: "1px solid #e0e0e0",
            marginBottom: "24px",
          }}
        >
          {crumbs.map((crumb, index) => (
            <div key={crumb}>
              <Typography>
                <StyledLink
                  onClick={() => goTo(`/${config[crumb].link()}`)}
                  variant="body2"
                >
                  {config[crumb].alias()}
                </StyledLink>
                {index != crumbs.length - 1 && (
                  <span style={{ margin: "0px 5px" }}>{">"}</span>
                )}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

//Styling
const StyledLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;
//Styling

export default Breadcrumbs;
