import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '370px',
  },
}));

const PaymentMethodForm = () => {
  const classes = useStyles();
  const elements = useElements();
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDataComplete, setIsDataComplete] = useState(false);

  const handleSubmit = async () => {
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href + '/result',
      }
    });
    if (error) setErrorMessage(error.message);
  }

  return stripe
    ? <div className={classes.formContainer}>
      <PaymentElement onChange={e => { setIsDataComplete(e.complete) }}/>
      <button onClick={handleSubmit} disabled={!isDataComplete} >Submit</button>
      {errorMessage && <div>{errorMessage}</div>}
    </div>
    : <CircularProgress />
}

export default PaymentMethodForm;