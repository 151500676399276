import axios from "axios";
import config from "../config";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const controller = {
  checkIfSigned: async function () {
    const response = await axios.get(`${prefix}/saas-contract/check`);
    return response.data.data;
  },
  acceptContract: async function (contractId) {
    const response = await axios.post(`${prefix}/saas-contract/sign-contract`, {
      contractId: contractId,
    });
    return response.data.success;
  },
};

export default controller;
