import { createTheme } from "@material-ui/core/styles";
import { deDE } from "@material-ui/core/locale";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#000",
      },
      secondary: {
        main: "#f37028",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "futura-pt",
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 16,
      },
      button: {
        fontSize: 16,
      },
    },
    shape: {
      borderRadius: "0.25rem",
    },
    overrides: {
      MuiListItem: {
        root: {
          alignItems: "center",
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: "#000",
        },
      },
      MuiPopover: {
        paper: { borderRadius: "0.25rem" },
      },
      MuiButton: {
        root: { borderRadius: "50px", borderWidth: "2px!important" },
      },
    },
  },
  deDE
);

export default theme;
