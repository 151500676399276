import React, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import controller from "../../controllers/filesController";
import Tooltip from "@material-ui/core/Tooltip";
import utils from "../../utils/utils";

const entryToViewEntry = (entry, creationDateField, expirationDateField) => {
  const e = { ...entry };
  e[creationDateField] = utils.formatDate(new Date(e[creationDateField]));
  if (expirationDateField) {
    e[expirationDateField] = utils.formatDate(new Date(e[expirationDateField]));
  }
  return e;
};

const checkDateDiff = (creationDate, expirationDate, warningPeriod) => {
  const res = new Date(expirationDate) - new Date() < warningPeriod;
  console.log(
    "checking diff",
    "creationDate:",
    creationDate,
    "expirationDate:",
    expirationDate,
    res
  );
  return res;
};

function MyRow({
  recursive,
  fields,
  idFieldName,
  entry,
  renderActions,
  level,
  onRowClick,
  onDelete,
  onEditClick,
  onAbortClick,
  creationDateField,
  expirationDateField,
  expiration,
  getWarningMessage,
}) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [outputFiles, setOutputFiles] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [viewEntry, setViewEntry] = useState(
    entryToViewEntry(entry, creationDateField, expirationDateField)
  );
  const [warning, setWarning] = useState(
    entry[expirationDateField] &&
      checkDateDiff(
        entry[creationDateField],
        entry[expirationDateField],
        expiration.warningTime
      )
  );
  const history = useHistory();
  const { projectId } = useParams();

  const NESTED_LEVEL_PADDING = 24;
  const ARROW_BUTTON_WIDTH = 40;
  const BUTTONS_AMOUNT = 1;

  const basicColumnWidth =
    (1100 - ARROW_BUTTON_WIDTH) / (fields.length + BUTTONS_AMOUNT);
  const adjustedColumnWidth =
    basicColumnWidth - (NESTED_LEVEL_PADDING * level) / fields.length;

  useEffect(() => {
    setViewEntry(
      entryToViewEntry(entry, creationDateField, expirationDateField)
    );
  }, [entry]);

  const onArrowClick = async (bool) => {
    if (bool) {
      const fetchedOutputFiles = await controller.getOutputByInputId(
        projectId,
        entry[idFieldName]
      );
      setOutputFiles(fetchedOutputFiles);
    }
    setOpen(bool);
  };

  return (
    <React.Fragment>
      {viewEntry && (
        <TableRow>
          {recursive && (
            <TableCell
              style={{
                padding: "0px",
                width: `${ARROW_BUTTON_WIDTH}px`,
                borderBottom: "0px",
              }}
            >
              {entry.hasChilds == 1 && (
                <IconButton size="small" onClick={() => onArrowClick(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
            </TableCell>
          )}

          {fields.map((field, index) => (
            <TableCell
              key={index}
              style={{
                width: `${adjustedColumnWidth}px`,
                boxSizing: "border-box",
              }}
            >
              {index == 0 && (
                <StyledDiv
                  warning={expiration && field === expiration.field && warning}
                  onClick={
                    viewEntry[field].slice(-4) !== ".dxf"
                      ? () => onRowClick(entry[idFieldName])
                      : undefined
                  }
                  dxf={viewEntry[field].slice(-4) !== ".dxf"}
                >
                  {expiration && field === expiration.field && warning ? (
                    <Tooltip
                      title={getWarningMessage(viewEntry[expirationDateField])}
                    >
                      <span>{viewEntry[field]}</span>
                    </Tooltip>
                  ) : (
                    <span>{viewEntry[field]}</span>
                  )}
                </StyledDiv>
              )}
              {index != 0 && viewEntry[field]}
            </TableCell>
          ))}

          <TableCell
            style={{ width: `${basicColumnWidth}px`, boxSizing: "border-box" }}
          >
            {renderActions(onDelete, entry, onEditClick, onAbortClick)}
          </TableCell>
        </TableRow>
      )}
      {viewEntry && entry.hasChilds == 1 && (
        <TableRow>
          <TableCell
            style={{
              padding: "0px",
              paddingLeft: `${NESTED_LEVEL_PADDING}px`,
              borderBottom: "0px",
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {outputFiles.map((innerEntry, index) => (
                    <MyRow
                      key={index}
                      recursive={recursive}
                      entry={innerEntry}
                      fields={fields}
                      idFieldName={idFieldName}
                      level={level + 1}
                      renderActions={renderActions}
                      onRowClick={onRowClick}
                      expiration={expiration}
                      getWarningMessage={getWarningMessage}
                      expirationDateField={expirationDateField}
                      creationDateField={creationDateField}
                    />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

//Styling
const StyledDiv = styled.div`
  display: inline-block;
  ${(props) => props.warning && `color: red`};
  ${(props) =>
    props.dxf &&
    `&:hover {
    cursor: pointer;
  }`};
`;
//Styling

export default MyRow;
