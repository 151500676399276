import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import authStore from "../Auth/authStore";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function Login(props) {

  const history = useHistory();
  const { t } = useTranslation();

  const cognitoLogin = async () => {
    history.replace("/login");
  };

  const cognitoLogout= async ()=>{
    authStore.logout();
  }

  return (
    <React.Fragment>
      {props.loggedIn ? (
        <Button style={{ color: "#fff" }} onClick={cognitoLogout} >
          {t("General:Logout")}
        </Button>
      ) : (
        <Button onClick={cognitoLogin} style={{ color: "#fff" }}>
          {t("General:Login")}
        </Button>
      )}
    </React.Fragment>
  );
}

export default Login;
