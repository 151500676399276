import React, {useState, useReducer, useEffect} from "react";
import { useTranslation } from "react-i18next";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const getInitialValues = (entries) => {
  let obj = {};
  for (let key of Object.keys(entries)) {
    obj[key] = false;
  }
  return obj;
}

function MultipleSelectInput({parameter, onValueUpdate}) {
  const valuesReducer = (values, action) => {
    const bValues = {...values};
    bValues[action.payload.id] = action.payload.value;
    return bValues;
  }

  const { t } = useTranslation();

  const [values, dispatchValues] = useReducer(valuesReducer, getInitialValues(parameter.dropDownValues));

  const handleChange = (event) => {
    dispatchValues({
      payload: {
        id: event.target.name,
        value: event.target.checked
      }
    });
    onValueUpdate({
      payload: {
        parameterId: event.target.name,
        value: event.target.checked,
        selected: true
      }
    });
  }

  return (
    <FormGroup>
      {values && Object.keys(parameter.dropDownValues)
      .map(key => (
        <FormControlLabel key={key}
          control={<Checkbox checked={values[key]} onChange={handleChange} name={key} />}
          label={t(`ProccessingBackend:Parameters:${parameter.parameterId}:dropDownValues:${key}`)}
        />
      ))}
    </FormGroup>
  );
}

export default MultipleSelectInput;
