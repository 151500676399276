import axios from "axios";
import config from "../config";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const controller = {
  getContingentSum: async function () {
    const response = await axios.get(`${prefix}/contingents/get-sum`);
    return response.data.data;
  },
  getAllContingents: async function () {
    const response = await axios.get(`${prefix}/contingents`);
    return response.data.data;
  },
  buyContingent: async function (contingentId, supportPackId) {
    const response = await axios.post(`${prefix}/contingents/buy-one`, {
      contingentId: contingentId,
      supportPackId: supportPackId,
    });
    return response.data.success;
  },
};

export default controller;
