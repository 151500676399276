import axios from "axios";
import config from "../config";
import utils from "../utils/utils";

const proto = `https:`;
const api = config.api;
const prefix = `${proto}//${api.host}/${api.base}`;

const controller = {
  getInfo: async function (projectId, fileId) {
    const response = await axios.get(
      `${prefix}/files/${projectId}/${fileId}/get-info`
    );
    return response.data.data.name;
  },

  getFiles: async function (id) {
    const response = await axios.get(`${prefix}/files/${id}`);
    let files = response.data.data;
    for (let file of files) {
      file.size = utils.humanFileSize(file.size);
    }
    return files;
  },

  uploadFile: async function (id, file, description) {
    const formData = new FormData();
    formData.append("pointcloud", file);
    formData.append("projectDescription", description);
    const response = await axios.post(
      `${prefix}/projects/files/${id}`,
      formData
    );
    return response;
  },

  deleteFile: async function (projectId, fileId) {
    await axios.delete(`${prefix}/files/${projectId}/${fileId}`);
  },

  editFile: async function (projectId, fileId, file) {
    await axios.put(`${prefix}/files/${projectId}/${fileId}`, file);
  },

  resolveName: async function (projectId, name) {
    const response = await axios.post(
      `${prefix}/files/${projectId}/resolve-name`,
      { name }
    );
    return response.data;
  },

  shareLink: async function (projectId, fileName) {
    const response = await axios.get(
      `/rib-downloader/generate-link/${projectId}/${fileName}`
    );
    return response.data;
  },

  getOutputByInputId: async function (projectId, inputFileId) {
    const response = await axios.get(
      `${prefix}/files/${projectId}/${inputFileId}`
    );
    for (let entry of response.data.data.outputFiles) {
      entry.size = utils.humanFileSize(entry.size);
    }
    return response.data.data.outputFiles;
  },

  getS3DownloadLink: async function (projectId, fileId) {
    const response = await axios.get(
      `${prefix}/files/${projectId}/${fileId}/download`
    );
    return response.data;
  },
};

export default controller;
