import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import authStore from "./authStore";

const ForceChangePassword: FC = () => {
  const { t } = useTranslation();

  const passwordValidationError = t("Auth:PasswordValidationError");
  const passwordMatchError = t("Auth:PasswordMatchError");

  const { values, isPasswordValid, isPasswordMatch, inProgress, error } =
    authStore;

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    authStore.setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => authStore.setConfirmNewPassword(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.validatePassword(values.newPw);
    authStore.checkPasswordsMatch(values.newPw, values.confirmNewPw);
    console.log(isPasswordMatch);
    authStore.isPasswordValid && authStore.isPasswordMatch && authStore.login();
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {t("Auth:Login")}
      </Typography>
      <Typography sx={messageStyles} variant="subtitle1">
        {t("Auth:ForceChangePassword")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }} component="div" color="error">
        {error}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={!isPasswordValid}
              required
              fullWidth
              name="new-password"
              label={t("Auth:NewPasswordLabel")}
              type="password"
              id="new-password"
              autoComplete="new-password"
              value={values.newPw}
              onChange={handlePasswordChange}
              helperText={!isPasswordValid && passwordValidationError}
              color="secondary"
              sx={textFieldsStyles}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!isPasswordMatch}
              required
              fullWidth
              name="confirm-new-password"
              label={t("Auth:ConfirmNewPasswordLabel")}
              type="password"
              id="confirm-new-password"
              autoComplete="new-password"
              value={values.confirmNewPw}
              onChange={handleConfirmPasswordChange}
              helperText={!isPasswordMatch && passwordMatchError}
              color="secondary"
              sx={textFieldsStyles}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          color="secondary"
          variant="contained"
          sx={buttonStyles}
          disabled={inProgress}
          disableElevation
        >
          {t("General:Update")}
        </Button>
      </Box>
    </>
  );
};

const textFieldsStyles = {
  "& label[data-shrink='true']": {
    color: "#ec6608",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      color: "#ec6608",
      borderColor: "#ec6608",
      "& legend": {
        color: "#ec6608",
      },
    },
  },
};

const buttonStyles = {
  mt: 3,
  mb: 2,
  backgroundColor: "#ec6608",
  ":hover": {
    backgroundColor: "#ec6608",
  },
};

const formStyles = { mt: 3, width: "100%" };

const messageStyles = { mt: 2, color: "primary.light" };

export default observer(ForceChangePassword);
