import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import controller from "../../controllers/filesController";
import MyTable from "../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import TimelineIcon from "@material-ui/icons/Timeline";
import Typography from "@material-ui/core/Typography";
import MyUploadFileModal from "./MyUploadFileModal";
import renderActions from "./Actions";
import MyEditModal from "../MyEditModal";
import config from "../../config";

function Files({ uploadInBackground, updateFiles }) {
  const [files, setFiles] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [fileToEdit, setFileToEdit] = useState();
  const [uploadId, setUploadId] = useState(0);
  const { projectId } = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  const WARNING_TIME = 1000 * 60 * 60 * 24 * 5;

  useEffect(() => {
    getAndSetFiles();
  }, [updateFiles]);

  useEffect(() => {
    if (fileToEdit != null) {
      setEditModalOpen(true);
    }
  }, [fileToEdit]);

  const getAndSetFiles = async () => {
    const fetchedFiles = await controller.getFiles(projectId);
    setFiles(fetchedFiles);
    setLoaded(true);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onEditClick = (file) => {
    setFileToEdit({ ...file });
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const onUploadFile = async (files, fileDescription) => {
    for (let file of files) {
      if (
        file.name.slice(-4) !== ".las" &&
        file.name.slice(-4) !== ".dxf" &&
        file.name.slice(-4) !== ".laz" &&
        file.name.slice(-4) !== ".csv"
      ) {
        window.alert(t("Files:WarningFormatUploads"));
        return;
      }
    }
    closeModal();
    for (let file of files) {
      const fileName = await controller.resolveName(projectId, file.name);
      setTimeout(
        () => uploadInBackground(projectId, file, fileName, fileDescription),
        500
      );
    }
  };

  const redirectToHistory = () => {
    history.push(`/${config.react.publicURL}/projects/${projectId}/history`);
  };

  const onDelete = (fileId) => {
    let bufferFiles = [...files];
    bufferFiles = bufferFiles.filter((file) => file.fileId !== fileId);
    setFiles(bufferFiles);
  };

  const openFile = (fileId) => {
    history.push(`${projectId}/${fileId}/processing`);
  };

  const editFile = async (editedFile) => {
    console.log(`File has just changed to`, editedFile);
    try {
      await controller.editFile(projectId, editedFile.fileId, editedFile);
      closeEditModal();
      getAndSetFiles();
    } catch (e) {
      window.alert(t("Files:SomethingWrong"));
    }
  };

  const getWarningMessage = (date) => {
    console.log("Warning message date:", date);
    return `${t("Files:FileDeletionPart1")} ${date} ${t(
      "Files:FileDeletionPart2"
    )}`;
  };

  return (
    <React.Fragment>
      {!loaded && <CircularProgress />}
      {loaded && (
        <React.Fragment>
          <div style={{ width: "100%", display: "flex" }}>
            <Tooltip title={t("Files:Upload")}>
              <IconButton
                style={{ padding: "0" }}
                color="secondary"
                onClick={openModal}
              >
                <PublishIcon fontSize="large" />
              </IconButton>
            </Tooltip>

            <Tooltip title={t("Files:History")}>
              <IconButton
                style={{ padding: "0" }}
                color="secondary"
                onClick={redirectToHistory}
              >
                <TimelineIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </div>

          <MyUploadFileModal
            closeModal={closeModal}
            open={modalOpen}
            onUploadFile={onUploadFile}
          />

          {files.length > 0 ? (
            <MyTable
              recursive
              entries={files}
              fields={["name", "description", "size", "createdAt"]}
              columnTitles={[
                t("Files:Name"),
                t("Files:Description"),
                t("Files:Size"),
                t("Files:CreatedAt"),
              ]}
              idFieldName={"fileId"}
              onDelete={onDelete}
              renderActions={renderActions}
              onRowClick={openFile}
              onEditClick={onEditClick}
              expiration={{ field: "name", warningTime: WARNING_TIME }}
              expirationDateField={"expirationDate"}
              getWarningMessage={getWarningMessage}
              creationDateField={"createdAt"}
            />
          ) : (
            <Typography component="h4" style={{ fontWeight: "500" }}>
              {t("Files:NotOneFileUploaded")}
            </Typography>
          )}

          {editModalOpen && (
            <MyEditModal
              closeModal={closeEditModal}
              open={editModalOpen}
              object={fileToEdit}
              idFieldName={"fileId"}
              fields={["name", "description"]}
              titles={[t("Files:Name"), t("Files:Description")]}
              editable={["description"]}
              editObject={editFile}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Files;
