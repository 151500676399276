import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentMethodForm from "./PaymentMethodForm";
import PaymentMethodResult from './PaymentMethodResult';
import config from "../../config";
import controller from "../../controllers/paymentController";
import CircularProgress from "@material-ui/core/CircularProgress";

const stripePromise = loadStripe(config.envs.stripe);

export function StripeSavePaymentMethodContainer() {
  const [setupIntentSecret, setSetupIntentSecret] = useState();
  useEffect(() => {
    (async () => {
      try {
        const res = await controller.getSetupIntentClientSecret();
        setSetupIntentSecret(res);
      } catch (e) {
        console.log(e)
      }
    })();
  }, []);

  if (!setupIntentSecret) return <CircularProgress />
  return (
    <Elements stripe={stripePromise} options={{ clientSecret: setupIntentSecret }}>
      <PaymentMethodForm />
    </Elements>
  )
}

export function PaymentMethodResultContainer() {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodResult />
    </Elements>
  ) 
}