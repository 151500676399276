import React, { useState, useEffect, useReducer } from "react"
import {
  ContentContainer,
  ContentAndNavContainer,
  InnerContainer,
  CenteringContainer,
} from "./components/containers"
import MyAppBar from "./components/MyAppBar/MyAppBar"
import theme from "./components/theme"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { ThemeProvider } from "styled-components"
import { useTranslation } from "react-i18next"
import MyDrawer from "./components/MyDrawer/MyDrawer"
import dims from "./components/dims"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import { observer } from "mobx-react-lite"
import Projects from "./components/Projects/Projects"
import Files from "./components/Files/Files"
import History from "./components/History/History"
import ProcessingInitiation from "./components/ProcessingInitiation/ProcessingInitiation"
import FileUploader from "./components/FileUploader"
import Breadcrumbs from "./components/Breadcrumbs"
import ProcessingInfo from "./components/ProcessingInfo/ProcessingInfo"
import Subscription from "./components/Subscription/Subscription"
import Contingent from "./components/Contingent/Contingent"
import StripeContainer, { PaymentMethodResultContainer } from "./components/Payment/StripeContainer"
import Footer from "./components/Footer/Footer"
import Imprint from "./components/Footer/Imprint"
import DataProtection from "./components/Footer/DataProtection"
import AGBs from "./components/Footer/AGBs"
import SaasContract from "./components/SaasContract"
import UploadsIndicator from "./components/Files/UploadsIndicator"
import config from "./config"
import controller from "./controllers/saasContractController"
import Register from "./components/Auth/Register"
import Login from "./components/Auth/Login"
import Confirm from "./components/Auth/Confirm"
import ResendCode from "./components/Auth/ResendCode"
import ForgotPassword from "./components/Auth/ForgotPassword/ForgotPassword"
import authStore from "./components/Auth/authStore"
import mainStore from "./components/Auth/mainStore"

function App() {
  const { t } = useTranslation()

  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    authStore.verifySession()
  }, [])

  const { isAppLoaded } = mainStore

  const { cognitoUser } = authStore

  const fileUploadsReducer = (fileUploads, action) => {
    const fileName = action.payload.fileName
    const progress = action.payload.progress
    const projectId = action.payload.projectId
    const bfileUploads = { ...fileUploads }
    if (!bfileUploads[projectId]) {
      bfileUploads[projectId] = {}
    }
    bfileUploads[projectId][fileName] = progress
    console.log("DISPATCH")
    return bfileUploads
  }

  const [fileUploads, dispatch] = useReducer(fileUploadsReducer, {})
  const [uploadObject, setUploadObject] = useState()
  const [updateFiles, setUpdateFiles] = useState(false)

  const [signedContract, setSignedContract] = useState(true)

  useEffect(() => {
    checkIfSigned()
  }, [signedContract])

  useEffect(() => {
    window.onbeforeunload = () => { }
    for (let projectGroup of Object.values(fileUploads)) {
      for (let progress of Object.values(projectGroup)) {
        console.log(progress)
        if (progress !== "done") {
          window.onbeforeunload = () => {
            return t("General:OnActiveUploads")
          }
          return
        }
      }
    }
  }, [fileUploads])

  useEffect(() => {
    const script = document.createElement("script")
    if (cognitoUser) {
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=d719c7ff-6211-488a-bbff-9e56ff834a4e"
      script.async = true
      script.id = "ze-snippet"
      document.body.appendChild(script)
    }

    return () => {
      if (document.body.querySelector("#ze-snippet")) {
        document.body.removeChild(script)
      }
    }
  }, [cognitoUser])

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const checkIfSigned = async () => {
    if (isAppLoaded && cognitoUser) {
      const CheckIfSigned = await controller.checkIfSigned()
      setSignedContract(CheckIfSigned)
    }
  }

  function Unauthenticated() {
    return (
      <CenteringContainer>
        <h3>{t("General:NotAuth")}</h3>
      </CenteringContainer>
    )
  }

  const uploadInBackground = (projectId, file, fileName, fileDescription) => {
    dispatch({
      payload: {
        projectId,
        fileName,
        progress: 0,
      },
    })
    setUploadObject({
      projectId,
      file,
      fileName,
      fileDescription,
    })
  }

  const onUploadProgress = (done, projectId, fileName, progress) => {
    progress = done ? t("General:Done") : Math.floor(progress * 100)
    dispatch({
      payload: {
        projectId,
        fileName,
        progress,
      },
    })
  }

  return (
    <React.Fragment>
      {isAppLoaded ? (
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MyAppBar
              toggleDrawer={toggleDrawer}
              dims={dims}
              loggedIn={cognitoUser}
            />

            <FileUploader
              uploadObject={uploadObject}
              onUploadProgress={onUploadProgress}
            />

            <ContentAndNavContainer dims={dims}>
              {cognitoUser && <MyDrawer dims={dims} open={drawerOpen} />}
              <ContentContainer open={drawerOpen} vertical>
                <div>
                  <InnerContainer vertical>
                    {cognitoUser && (
                      <SaasContract
                        open={!signedContract}
                        updateSignedContract={setSignedContract}
                      />
                    )}

                    <UploadsIndicator
                      fileUploads={fileUploads}
                      updateFiles={setUpdateFiles}
                      updateFilesState={updateFiles}
                    />
                    <Switch>
                      <Route exact path={`/`}>
                        <Redirect to={`/${config.react.publicURL}/projects`} />
                      </Route>

                      <Route exact path={`/${config.react.publicURL}/projects`}>
                        {cognitoUser ? (
                          <React.Fragment>
                            <Breadcrumbs crumbs={["projects"]} />
                            <Projects />
                          </React.Fragment>
                        ) : (
                          <Unauthenticated />
                        )}
                      </Route>

                      <Route
                        exact
                        path={`/${config.react.publicURL}/projects/:projectId`}
                      >
                        {cognitoUser ? (
                          <React.Fragment>
                            <Breadcrumbs crumbs={["projects", "project"]} />
                            <Files
                              uploadInBackground={uploadInBackground}
                              updateFiles={updateFiles}
                            />
                          </React.Fragment>
                        ) : (
                          <Unauthenticated />
                        )}
                      </Route>

                      <Route
                        exact
                        path={`/${config.react.publicURL}/projects/:projectId/:fileId/processing`}
                      >
                        {cognitoUser ? (
                          <React.Fragment>
                            <Breadcrumbs
                              crumbs={["projects", "project", "file"]}
                            />
                            <ProcessingInitiation />
                          </React.Fragment>
                        ) : (
                          <Unauthenticated />
                        )}
                      </Route>

                      <Route
                        exact
                        path={`/${config.react.publicURL}/projects/:projectId/history`}
                      >
                        {cognitoUser ? (
                          <React.Fragment>
                            <Breadcrumbs
                              crumbs={["projects", "project", "history"]}
                            />
                            <History />
                          </React.Fragment>
                        ) : (
                          <Unauthenticated />
                        )}
                      </Route>

                      <Route
                        exact
                        path={`/${config.react.publicURL}/projects/:projectId/history/:processingId`}
                      >
                        <React.Fragment>
                          <Breadcrumbs
                            crumbs={[
                              "projects",
                              "project",
                              "history",
                              "processing",
                            ]}
                          />
                          <ProcessingInfo />
                        </React.Fragment>
                      </Route>

                      { /*
                        <Route exact path={`/${config.react.publicURL}/subscription`}>
                          {cognitoUser ? (
                            <React.Fragment>
                              // NOTE: Breadcrumbs should be commented
                              // but JSX doesnt support nested comments
                              <Breadcrumbs crumbs={["contingent"]} />
                              <Subscription />
                            </React.Fragment>
                          ) : (
                            <Unauthenticated />
                          )}
                        </Route>
                      */ }

                      <Route exact path={`/${config.react.publicURL}/subscription/result`}>
                        {cognitoUser ? (
                          <React.Fragment>
                            {/* <Breadcrumbs crumbs={["contingent"]} /> */}
                            <PaymentMethodResultContainer />
                          </React.Fragment>
                        ) : (
                          <Unauthenticated />
                        )}
                      </Route>

                      <Route
                        exact
                        path={`/${config.react.publicURL}/contingent`}
                      >
                        {cognitoUser ? (
                          <React.Fragment>
                            <Breadcrumbs crumbs={["contingent"]} />
                            <Contingent />
                          </React.Fragment>
                        ) : (
                          <Unauthenticated />
                        )}
                      </Route>

                      <Route exact path={`/${config.react.publicURL}/imprint`}>
                        <React.Fragment>
                          <Breadcrumbs crumbs={["imprint"]} />
                          <Imprint />
                        </React.Fragment>
                      </Route>

                      <Route
                        exact
                        path={`/${config.react.publicURL}/data-protection`}
                      >
                        <React.Fragment>
                          <Breadcrumbs crumbs={["dataProtection"]} />
                          <DataProtection />
                        </React.Fragment>
                      </Route>

                      <Route exact path={`/${config.react.publicURL}/agbs`}>
                        <React.Fragment>
                          <Breadcrumbs crumbs={["agbs"]} />
                          <AGBs />
                        </React.Fragment>
                      </Route>

                      <Route exact path={`/register`}>
                        <Register />
                      </Route>
                      <Route path={`/register/confirm`}>
                        <Confirm />
                      </Route>
                      <Route path={`/register/new-code`}>
                        <ResendCode />
                      </Route>
                      <Route exact path={`/login`}>
                        <Login />
                      </Route>
                      <Route path={`/login/new-password`}>
                        <ForgotPassword />
                      </Route>
                    </Switch>
                  </InnerContainer>
                </div>
                <div>
                  <Footer />
                </div>
              </ContentContainer>
            </ContentAndNavContainer>
          </ThemeProvider>
        </MuiThemeProvider>
      ) : (
        <h3> {t("General:Loading")} </h3>
      )}
    </React.Fragment>
  )
}

export default observer(App)
