import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

function PointCloudInput({ parameter, onValueUpdate, pointCloudFiles }) {
  const { t } = useTranslation();
  const [selectedPointClouds, setSelectedPointClouds] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPointClouds(
      typeof value === "string" ? value.split(",") : value
    );
  };

  React.useEffect(()=>{
    onValueUpdate({
        payload: {
          parameterId: parameter.parameterId,
          value: selectedPointClouds,
          selected: true,
        },
      });
  }, [selectedPointClouds])

  return (
    <>
      {pointCloudFiles && pointCloudFiles.length > 0 ? (
        <FormControl>
          <InputLabel htmlFor="10">{parameter.parameterName}</InputLabel>
          <Select
            value={selectedPointClouds}
            name={parameter.parameterId}
            style={{ minWidth: "220px" }}
            onChange={handleChange}
            defaultValue=""
            multiple
          >
            {pointCloudFiles.map((file, index) => (
              <MenuItem key={index} value={file}>
                {file.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          disabled
          defaultValue={t(`General:NoFilesFound`)}
          size="small"
        />
      )}
    </>
  );
}

export default PointCloudInput;
