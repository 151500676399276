import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import controller from '../../controllers/projectsController';

function MyCreateProjectModal(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const { t } = useTranslation();

  const handleChange = event => {
    const inputId = event.target.id;
    const inputVal = event.target.value;

    switch (inputId) {
      case 'name':
        setName(inputVal);
        break;
      case 'description':
        setDescription(inputVal);
        break;
    }

  }

  const createProject = async () => {
    const aProject = await controller.createProject(name, description);
    aProject.createdAt = new Date(aProject.createdAt);
    props.onCreate(aProject);
  }

  return (
    <Modal
      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <MyPaper>
        <Typography style={{margin: "25px 0 10px", fontWeight: "500"}} component="h4">{t("Projects:NewProject")}:</Typography>

          <MyTextField
          id='name'
          label={t("Projects:ProjectName")}
          onChange={handleChange}
          variant='filled'
          size='small' />

          <MyTextField
          id='description'
          label={t("Projects:Description")}
          multiline
          rows={3}
          onChange={handleChange}
          variant='filled'
          size='small'
          />

          <Button
            style={{width:'80px', alignSelf: 'flex-end'}}
            size='small' 
            variant="outlined"
            color="secondary"
            onClick={createProject}>
            {t("Projects:Create")}
          </Button>
        </MyPaper>
      </Fade>
    </Modal>
  );
}

const MyTextField = styled(TextField)`
  margin-bottom: 20px;
`;

const MyPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width 400px;
  height: 300px;
  outline: none;
  padding: 0px 15px;
`;

export default MyCreateProjectModal;
