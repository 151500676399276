import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import controller from "../../controllers/historyController";
import Paper from "@material-ui/core/Paper";
import utils from "../../utils/utils";

function ProcessingInfo() {
  const { t, i18n } = useTranslation();

  const [processing, setProcessing] = useState();
  const [processingLoaded, setProcessingLoaded] = useState(false);
  const { projectId, processingId } = useParams();

  useEffect(() => {
    setAndFetchProcessing(projectId, processingId);
  }, [i18n.language]);

  useEffect(() => {
    if (processing) {
      setProcessingLoaded(true);
    }
  }, [processing]);

  const setAndFetchProcessing = async () => {
    const fetchedProcessing = await controller.getProcessing(
      projectId,
      processingId
    );
    fetchedProcessing.processing[0].status = t(
      `Proccessing:${fetchedProcessing.processing[0].status}`
    );
    setProcessing(fetchedProcessing);
  };

  return (
    <React.Fragment>
      {processingLoaded && (
        <Paper
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "10px 10px",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h5">
            {processing.processing[0].processingName}
          </Typography>
          <Typography variant="overline" style={{ marginBottom: "20px" }}>
            {processing.processing[0].createdAt},{" "}
            {processing.processing[0].processingTime &&
              `${utils.NumToTime(
                processing.processing[0].processingTime
              )},`}{" "}
            {processing.processing[0].status}
          </Typography>
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <Typography variant="subtitle2">Parameter:</Typography>
            {processing.parameters.map((entry) => (
              <Typography key={entry.name} variant="body2">
                {entry.name}:{" "}
                {entry.value != null && entry.parameterType !== "dxfFile"
                  ? entry.value.toFixed(1)
                  : entry.dxfName}
              </Typography>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="subtitle2">
                {t("Proccessing:InputFile")}
              </Typography>
              <Typography variant="body2">
                {processing.processing[0].inputFileName} (
                {utils.humanFileSize(processing.processing[0].size)})
              </Typography>
            </div>
            {processing.outputfile[0] && (
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="subtitle2">
                  {t("Proccessing:GeneratedFile")}
                </Typography>
                <Typography variant="body2">
                  {processing.outputfile[0].name} (
                  {utils.humanFileSize(processing.outputfile[0].size)})
                </Typography>
              </div>
            )}
          </div>
        </Paper>
      )}
    </React.Fragment>
  );
}

export default ProcessingInfo;
